/* BRAND COLORS
#30409a Blue
#f37121 Orange
Use Blue for headings, and orange as accents -- border colors, arrows, etc. */

/* HEADER AND NAV */
.nav-logo-block {
	margin-top: 0.5rem;
	margin-left: 0.5rem;
}

.footer-social-icon-link:hover {
	background-color: #f37121;
}

.header-nav-bar-content-wrap {
	margin-top: 47px;
}

.header-nav-bar-content-wrap.lower {
	margin-top: 50px;
}

.w-dropdown {
	margin-left: 10px;
	margin-right: 10px;
}

.nav-link {
	font-size: 1rem;
}

.nav-dropdown-link:hover {
	background-color: #30409a;
}

.nav-section-header {
	height: 132px;
}

.desktop-navigation .nav-link.selected {
	color: #30409a;
}

.nav-link.w--current {
	border-bottom-color: #f37121;
}

.nav-link.desktop-dropdown-link:hover {
	border-bottom-color: #f37121;
}

.desktop-navigation .nav-dropdown:hover .nav-link {
	border-bottom-color: #f37121;
	color: #30409a;
	font-weight: 600;
	text-decoration: none;
}

.newsletter-signup-button {
	height: auto;
	padding: 0;
	border: 0 none;
	background-color: transparent;
	background-image: none;
	color: #000;
}

.newsletter-signup-button.w--current {
	color: #000;
}

.newsletter-signup-button:hover {
	padding: 0;
	background-image: none;
	color: #f37121;
}

.newsletter-signup-button:hover .ecommerceLinkImg {
	opacity: 0.7;
}

.ecommerceLinkImg {
	width: 1.1em;
	height: 1.1em;
	margin-right: 0.5em;
	float: left;
	opacity: 0.35;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.ecommerceLinkText,
.cart-number-of-items {
	float: left;
	font-weight: 700;
	font-size: 0.825rem;
	text-align: right;
	text-transform: uppercase;
	text-decoration: none;
	line-height: 0.825rem;
	color: inherit;
	margin-top: 1px;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

/* SLIDESHOW */
.slider-image-div-block.slide-1-image {
	background-position: 50% 50%;
}

.more-text-arrow-link.more-text-arrow-link-slider {
	color: #30409a;
	text-decoration: none;
}

.more-text-arrow-link.more-text-arrow-link-slider:hover {
	color: #f37121;
	text-decoration: none;
}

.cms-resp-slideshow-paging span.active {
	background-color: #f37121;
}

.slider-caption-paragraph h1 {
	color: #30409a;
}

/* CONTENT BOXS */
.colored-content-box {
	border-top: 2px solid #f37121;
}

.content-box-title {
	color: #30409a;
}

.homepage-section .content-box-title a {
	color: #30409a;
}

.homepage-section .content-box-title a:hover {
	color: #f37121;
}

.homepage-section .cb-row a {
	color: #30409a;
}

.homepage-section .cb-row .more-text-arrow-link:hover {
	color: #f37121;
}

.more-text-arrow-link {
	background-image: url('/20150114134215/assets/images/arrow-triangle_skinny_30409a-blue.svg');
}

.more-text-arrow-link:hover {
	background-image: url('/20150114134215/assets/images/arrow-triangle_skinny_f37121-orange.svg');
}

.column.last {
	border-bottom: 0 none;
}

/* MMENU */
.mobile-navigation-menu li:hover {
	background-color: #f37121;
}

.mobile-navigation-menu li.active a,
.mobile-navigation-menu li.active span.nav-a,
.mobile-navigation-menu li.active.mm-selected a,
.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next),
.mm-menu .mm-listview > li.mm-selected > span {
	background-color: #30409a;
}

.mobile-navigation-menu li.active.mobile-cart a {
	background-color: #000;
}

.ecommerceLinkImgMobile {
	width: 1.1em;
	height: 1.1em;
	margin-right: 0.5em;
	float: left;
	opacity: 0.65;
	margin-top: 4px;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.ecommerceLinkTextMobile {
	float: left;
	text-align: right;
}

/* FOOTER */
.footer-signoff-link:hover {
	color: #f37121;
}

.back-to-top-link:hover {
	background-image: url("/20150114134215/assets/images/arrow-medium-up-f37121-orange.svg");
	color: #f37121;
}

#GRIPFooterLogo {
	float: none;
	margin: 1rem auto 1.5rem;
}

.back-to-top-div {
	margin-top: 0;
}

/* INSIDE PAGE MAIN CONTENT BACKGROUND */
.wide-background.wide-background-inside-page {
	padding-top: 20px;
}

.section {
	background-image: none;
}

.left-nav-list-item-link.selected {
	color: #30409a;
	background-image: url('/20150114134215/assets/images/arrow-triangle_skinny_30409a-blue.svg');
	background-position: 0px 50%;
	background-size: 14px 14px;
	background-repeat: no-repeat;
}

.left-nav-list-item-link:hover {
	color: #f37121;
	background-image: url('/20150114134215/assets/images/arrow-triangle_skinny_f37121-orange.svg');
	background-position: 0px 50%;
	background-size: 14px 14px;
	background-repeat: no-repeat;
}

.left-nav-list-item-link.nested-left-nav-link.selected {
	color: #30409a;
	background-image: url("/20150114134215/assets/images/arrow-triangle_skinny_30409a-blue.svg");
	background-position: 0px 50%;
	background-size: 14px 14px;
	background-repeat: no-repeat;
}

.left-nav-list-item-link.nested-left-nav-link:hover {
	color: #f37121;
	background-image: url("/20150114134215/assets/images/arrow-triangle_skinny_f37121-orange.svg");
	background-position: 0px 50%;
	background-size: 14px 14px;
	background-repeat: no-repeat;
}

.breadcrumbs-list-item a {
	color: #30409a;
}

.breadcrumbs-list-item a:hover {
	color: #f37121;
}

.left-nav-top-rule {
	background-color: #f37121;
}

.inside-page-nested-right-column .box {
	border-top: 2px solid #f37121;
}

/* UAT TEXT STYLES */
.page-title {
	color: #30409a;
}

.inside-page-nested-row h1,
.inside-page-nested-row h2,
.inside-page-nested-row h3,
.inside-page-nested-row h4,
.inside-page-nested-row h5,
.inside-page-nested-row h6 {
	color: #30409a;
}

.inside-page-nested-row a {
	color: #30409a;
}

.inside-page-nested-row a:hover {
	color: #f37121;
	cursor: pointer;
}

#message.success {
	border: 2px solid #6dad33;
	color: #000;
}

.styled thead th {
	background-color: #30409a;
}

.tableHeader {
	background-color: #30409a;
}

.cms_buttons .primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.button_primary:hover,
button:hover,
.button:hover,
.homepage-section .cb-row a.button:hover,
.primary:hover,
.button.secondary:hover,
.button.primary:hover,
.homepage-section .cb-row a.button.primary:hover {
	background-color: #f37121;
}

/* ECOMMERCE */
.product-attribute-file-upload label,
#products_module.responsive .add_to_cart_form .attributeSelectionLabel {
	font-weight: 400;
}

.product-attribute .contentbox_item.attribute-type-singleline > br {
	display: none;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info {
	padding-right: 15px;
}

/* RESPONSIVE STYLES */
@media (min-width: 991px) { /* Pushes the slideshow down to correct location now that the header is taller */
	.section.cb1 {
		padding-top: 132px;
	}

	.wide-background.wide-background-inside-page {
		padding-top: 132px !important;
	}
}

@media (max-width: 1100px) {
	.w-dropdown {
		margin-left: 0px;
		margin-right: 0px;
	}
}

@media (max-width: 991px) {
	#cart-link.newsletter-signup-button {
		position: relative;
		margin-right: 20px;
	}

	.ecommerceLinkText {
		display: none;
	}

	.ecommerceLinkImg {
		width: 1.9em;
		height: 1.9em;
	}

	.cart-number-of-items {
		background-color: #30409a;
		padding: 5px;
		border-radius: 3px;
		color: #fff;
		position: absolute;
		top: 50%;
		left: 65%;
		padding: 3px;
		border-radius: 3px;
		line-height: 0.6875rem;
		font-size: 0.75em;
		min-width: 1rem;
		text-align: center;
	}

	.header-nav-bar-content-wrap.lower,
	.header-nav-bar-content-wrap {
		margin-top: 0;
	}

	.section.cb1 {
		padding-top: 0;
	}

	.newsletter-div {
		margin-top: 2.85rem;
	}

	.nav-menu-button {
		width: 5.5rem;
		height: 8.3rem;
	}

	.nav-menu-button-icon {
		margin-top: 2.5rem;
	}

	.nav-menu-button:hover {
		background-color: #f37121;
	}

	.nav-menu-button.w--open {
		background-color: #f37121;
	}

	.newsletter-signup-button:hover {
		background-color: transparent;
	}
}

@media (max-width: 767px) {
	.newsletter-div {
		clear: none;
		float: right;
	}

	.nav-logo-block {
		width: 240px;
	}

	.newsletter-div {
		margin-right: 1rem;
		margin-left: 0;
	}
}

@media (max-width: 510px) {
	.nav-logo-block {
		width: 133px;
	}

	.nav-section-header {
		height: 85px;
	}

	.nav-menu-button {
		width: 4.5rem;
		height: 85px;
	}

	.nav-menu-button-icon {
		margin-top: 1.3rem;
	}

	.newsletter-div {
		margin-top: 1.8rem;
		margin-right: 1.5rem;
	}

	.newsletter-div {
		display: flex;
		flex-direction: column;
		margin-right: 1.3rem;
		margin-top: 1.3rem;
		text-align: center;
	}

	.newsletter-signup-button {
		margin: 0;
	}

	.newsletter-signup-button.last {
		margin: 0;
	}
}

@media (max-width: 479px) {
	.nav-logo-block {
		width: 130px;
	}
}
