/* color contrast issues */
/* /core/libraries/tablesaw/assets/2.0.3/tablesaw.bare.css */
.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus {
	color: #14639f;
	background-color: #ffffff;
}

/* /core/public/shared/assets/css/screen.css */
.search-facets .search-facet .price-range-button a {
	background-color: #666666;
	color: #ffffff;
}

/* /core/public/shared/assets/css/uitags.css */
.ui-form-legend h3 .ui-form-legend-step {
	background: #ffffff;
	color: #666666;
}

/* focus outline */
/* /core/libraries/slickSlider/assets/1.4.1/slick.css */
.slick-list:focus {
	outline: 1px dotted #cccccc;
}

/* relative units in CSS property values */
/* /core/libraries/magnificPopup/assets/1.0.0/magnific-popup.css */
.mfp-counter {
	font-size: 0.75rem;
	line-height: 1.125rem;
}

.mfp-figure small {
	font-size: 0.75rem;
	line-height: 0.875rem;
}

/* /core/public/shared/assets/css/jquery.mmenu/5.5.3/jquery.mmenu.all.css */
.mm-listview {
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.mm-listview .mm-divider {
	font-size: 0.625rem;
	line-height: 25px;
}

em.mm-counter {
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.mm-search input {
	font-size: 0.875rem;
	line-height: 1.625rem;
}

.mm-noresultsmsg {
	font-size: 1.3125rem;
}

.mm-indexer {
	font-size: 0.75rem;
}

/* core/public/shared/assets/css/uitags.css */
.ui-form-legend {
	font-size: 1.0625rem;
}

.ui-form-note-extra {
	font-size: 0.6875rem;
}
