body {
	height: 100%;
	font-family: 'Open Sans', sans-serif;
	font-size: 0.9375rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.page-wrapper {
	color: #626262;
}

h1 {
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-family: 'Open Sans Condensed', sans-serif;
	color: #000;
	font-size: 2rem;
	line-height: 2.5rem;
	font-weight: 700;
}

h2 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #ee0008;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 600;
}

h3 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #ee0008;
	font-size: 1.2rem;
	line-height: 1.7rem;
	font-weight: 600;
}

h4 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #000;
	font-size: 1.1rem;
	line-height: 1.6rem;
	font-weight: 600;
}

h5 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #000;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
}

h6 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #000;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 700;
	text-transform: uppercase;
}

p {
	color: #000;
	font-size: 0.95rem;
	line-height: 1.5rem;
	font-weight: 400;
}

a {
	-webkit-transition: background-color 200ms ease, color 200ms ease, opacity 200ms ease, border-color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease, opacity 200ms ease, border-color 200ms ease;
	color: #ee0008;
	background-color: transparent;
	font-weight: 600;
	text-decoration: none;
}

a:hover {
	color: #ee0008;
	text-decoration: underline;
	background-color: transparent;
}

a.w--current {
	color: #000;
}

ul {
	margin-top: 0.25rem;
	margin-bottom: 0.75rem;
	padding-left: 30px;
	color: #000;
}

ol {
	margin-top: 0.25rem;
	margin-bottom: 0.75rem;
	padding-left: 30px;
}

li {
	color: #000;
	font-size: 0.95rem;
}

label {
	display: block;
	margin-bottom: 5px;
	color: #000;
}

.button {
	display: inline-block;
	margin-top: 0.25rem;
	margin-right: 20px;
	margin-bottom: 0.25rem;
	padding: 0.375rem 1rem;
	background-color: #000;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	color: #fff;
	font-size: 0.95rem;
	font-weight: 400;
	text-align: center;
	text-decoration: none;
	text-transform: capitalize;
}

.button:hover {
	background-color: #d81118;
	color: #fff;
	text-decoration: none;
}

.button.header-search-button {
	width: 30%;
	height: 50px;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	float: left;
}

.button.secondary-button {
	background-color: gray;
}

.button.secondary-button:hover {
	background-color: #d81118;
}

.nav-section {
	position: relative;
	background-color: #fff;
	box-shadow: 0 3px 0 0 hsla(0, 0%, 51%, 0.5);
}

.nav-logo-block {
	width: calc( 300px - 0.5rem );
	margin-top: 1rem;
	color: #000;
	font-size: 1.25rem;
	line-height: 1.125rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.nav-logo-block:hover {
	color: #555;
}

.nav-menu {
	float: right;
	display: flex;
	justify-content: flex-end;
}

.nav-link {
	margin-right: 1px;
	margin-left: 1px;
	padding: 0.75rem 0.73rem 0.4rem;
	border-bottom: 6px solid rgba(237, 28, 36, 0);
	font-family: 'Open Sans', sans-serif;
	color: #000;
	font-size: 0.8rem;
	line-height: 1.3rem;
	font-weight: 600;
	text-transform: uppercase;
}

.nav-link:hover {
	padding-bottom: 0.4rem;
	border-bottom: 6px solid #ee0008;
	background-color: transparent;
	color: #ee0008;
	text-decoration: none;
}

.nav-link.w--current {
	border-bottom-color: #ee0008;
	color: #d81118;
	font-weight: 600;
	text-decoration: none;
}

.nav-link.w--current:hover {
	border-bottom-color: #ee0008;
	background-color: transparent;
	color: #ee0008;
}

.nav-link.dropdown-toggle {
	text-align: center;
}

.nav-link.desktop-dropdown-link {
	padding-right: 0.73rem;
	padding-left: 0.73rem;
}

.nav-link.desktop-dropdown-link:hover {
	border-bottom-color: #ee0008;
}

.nav-link.desktop-dropdown-link.w--current {
	color: #ee0008;
	font-weight: 600;
}

.nav-dropdown {
	font-weight: 600;
}

.nav-dropdown-icon {
	width: 1rem;
	height: 1rem;
}

.nav-menu-button {
	position: relative;
}

.nav-dropdown-list {
	display: none;
	padding: 0.625rem 0.75rem 0.75rem;
	background-color: #fff;
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
}

.nav-dropdown-list.w--open {
	display: none;
	margin-top: 1px;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	background-color: #fff;
}

.nav-dropdown-link {
	padding: 0.5rem;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.9rem;
	text-decoration: none;
}

.nav-dropdown-link:hover {
	background-color: #d81118;
	color: #fff;
	text-decoration: none;
}

.nav-dropdown-link.last-dropdown-link {
	border-bottom: 1px none rgba(0, 0, 0, 0.15);
}

.section {
	position: relative;
	background-image: -webkit-linear-gradient(270deg, hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 100%, 0.4)), url('/20150114134215/assets/images/ps_neutral.png');
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 100%, 0.4)), url('/20150114134215/assets/images/ps_neutral.png');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, 8px;
}

.section.footer-section {
	padding-top: 1rem;
	background-color: #474848;
	background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.2) 1%, rgba(0, 0, 0, 0.8)), url('/20150114134215/assets/images/brushed_alu_dark.png');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 1%, rgba(0, 0, 0, 0.8)), url('/20150114134215/assets/images/brushed_alu_dark.png');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, 200px;
}

.section.footer-section .w-container .w-row {
	margin: 0;
}

.section.content-box-section-1 {
	background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/20150114134215/assets/images/ps_neutral.png');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('/20150114134215/assets/images/ps_neutral.png');
	background-size: auto, 8px;
	box-shadow: none;
}

.column {
	padding-right: 10px;
	padding-bottom: 2.5rem;
	padding-top: 2.5rem;
	padding-left: 10px;
}

.footer-signoff-row .column {
	padding-top: 0;
}

.column.left-signoff-column {
	padding-bottom: 0px;
	padding-left: 20px;
}

.column.center-signoff-column {
	padding-bottom: 0px;
	text-align: center;
}

.column.twitter-column {
	padding-bottom: 0px;
}

.column.row-1-column {
	background-color: transparent;
}

.divider {
	height: 2px;
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
	background-color: #e0e0e0;
}

.breadcrumbs-list {
	margin-top: 0rem;
	margin-bottom: 1rem;
	color: #ccc;
	font-weight: 600;
}

.breadcrumbs-list-item {
	display: inline-block;
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	font-size: 0.8rem;
	line-height: 1.4rem;
}

.breadcrumbs-list-divider {
	display: inline-block;
	margin-left: 0.5rem;
}

.split-50 {
	width: 50%;
	min-width: 0px;
	float: left;
}

.inside-page-row {
	padding-top: 1.5rem;
	padding-bottom: 2rem;
}

.inside-page-left-column {
	padding-top: 1.25rem;
}

.inside-page-content-column {
	padding-top: 0px;
	padding-left: 0px;
}

.left-nav-list {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
}

.left-nav-list.nested-left-nav-list {
	margin-top: 2px;
	margin-bottom: 0rem;
	margin-left: 20px;
	padding-bottom: 0rem;
	box-shadow: 0 -1px 0 0 hsla(0, 0%, 45%, 0.15);
}

.left-nav-list-item {
	margin-top: 1px;
	box-shadow: 0 1px 0 0 #e0e0e0;
}

.left-nav-list-item.nested-left-nav-list-item {
	box-shadow: 0 1px 0 0 #e0e0e0;
}

.left-nav-list-item.nested-left-nav-list-item.last-left-nav-list-item {
	box-shadow: none;
}

.left-nav-list-item-link {
	display: block;
	padding: 0.5rem 0.5rem 0.5rem 1rem;
	color: #000;
	font-size: 0.93rem;
	line-height: 1.33rem;
	font-weight: 600;
	text-decoration: none;
}

.left-nav-list-item-link:hover {
	padding-left: 1rem;
	background-position: 0px 50%;
	background-size: 14px 14px;
	background-repeat: no-repeat;
	color: #ee0008;
	text-decoration: none;
}

.left-nav-list-item-link.w--current {
	background-position: 0px 50%;
	background-size: 14px 14px;
	background-repeat: no-repeat;
	color: #d81118;
}

.left-nav-list-item-link.w--current:hover {
	text-decoration: none;
}

.left-nav-list-item-link.nested-left-nav-link {
	color: #363636;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.left-nav-list-item-link.nested-left-nav-link:hover {
	color: #ee0008;
	text-decoration: none;
}

.left-nav-list-item-link.nested-left-nav-link.w--current {
	color: #ee0008;
	font-weight: 600;
}

.left-nav-list-item-link.current-section-not-current-page {
	padding-left: 1.1rem;
	background-image: none;
	box-shadow: 0 1px 0 0 #e9e9e9;
	color: #ee0008;
}

.inside-page-nested-row {
	padding-top: 0.5rem;
}

.inside-page-right-column-heading {
	margin-top: 0rem;
	color: #000;
	font-size: 1rem;
	line-height: 1.5rem;
}

.inside-page-right-column-section-wrap {
	margin-bottom: 1rem;
	margin-left: 20px;
	padding: 0.75rem 0.5rem 0.25rem;
	border-top: 2px solid #d81118;
}

.content-box-image-link {
	display: block;
	height: 160px;
	margin-bottom: 1rem;
	background-image: url('/20150114134215/assets/images/FPO-CB-Photo_660x373.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.content-box-image-link.testimonial-image {
	height: 160px;
	background-image: url('/20150114134215/assets/images/Aerial Photo_660x373.jpg');
	background-size: cover;
}

.content-box-text {
	display: none;
	color: #474848;
}

.column-content-wrap {
	margin-right: 10px;
	margin-bottom: 2rem;
	margin-left: 10px;
}

.column-content-wrap.social-media-wrap {
	margin-bottom: 1rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid gray;
	text-align: center;
}

.column-content-wrap.navigation-column {
	padding-right: 15px;
}

.footer-signoff-row {
	padding-bottom: 0.5rem;
}

.footer-grip-link {
	width: 130px;
	height: 28px;
	min-width: 0px;
	padding-left: 32px;
	float: right;
	background-image: url('/20150114134215/assets/images/globalreach_gradient_blue.png');
	background-position: 50% 50%;
	background-size: 130px 28px;
	background-repeat: no-repeat;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #555;
	font-size: 0.5rem;
	line-height: 0.625rem;
	text-decoration: none;
	text-transform: uppercase;
}

.footer-grip-link:hover {
	color: #555;
}

.footer-signoff-text {
	display: inline-block;
	color: #888;
	text-align: center;
}

.footer-signoff-link {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	color: #fff;
	font-weight: 400;
	text-decoration: none;
}

.footer-signoff-link:hover {
	color: #ee0008;
	text-decoration: none;
}

.footer-signoff-link-wrap {
	display: block;
	margin-right: 1rem;
	margin-left: 1rem;
}

.footer-list-item {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.footer-social-icon-link {
	width: 1.75rem;
	height: 1.75rem;
	min-width: 0px;
	margin: 0.5rem 0.5rem 0.25rem;
	float: none;
	background-color: rgba(0, 0, 0, 0.5);
}

.footer-social-icon-link:hover {
	background-color: #d81118;
}

.footer-social-icon-image {
	float: left;
}

.inside-page-right-column-list-item {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid #e9e9e9;
	font-size: 0.95rem;
	line-height: 1.45rem;
}

.inside-page-content-image {
	margin-bottom: 15px;
	background-color: #e0e0e0;
}

.inside-page-intro {
	margin-bottom: 1rem;
	color: #000;
	font-size: 1.25rem;
	line-height: 2rem;
}

.header-container {
	position: relative;
}

.header-search-wrap {
	position: absolute;
	top: 100%;
	right: 0px;
	display: none;
	overflow: hidden;
	min-width: 240px;
	background-color: rgba(0, 0, 0, 0.09);
}

.header-search-field {
	display: inline-block;
	width: 68%;
	height: 50px;
	margin-right: 2%;
	margin-bottom: 0px;
	float: left;
	border: 0px solid #000;
	border-radius: 0px;
}

.header-search-field:focus {
	border-color: transparent;
	box-shadow: inset 0 0 0 0 #000;
}

.header-search-session-message {
	width: 100%;
	height: 2px;
	max-height: 2px;
	max-width: 100%;
	margin-top: 0px;
	padding: 0px;
	background-color: transparent;
}

.form-field {
	min-height: 2.5rem;
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	border-radius: 0px;
	color: #474848;
	font-size: 0.85rem;
}

.footer-column-title {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.625rem;
	font-weight: 600;
	text-transform: uppercase;
}

.left-nav-wrap {
	margin-top: 0rem;
	padding-right: 15px;
}

.sidebar-search-form-wrap {
	margin-bottom: 5px;
}

.footer-list-link {
	display: block;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	color: #fff;
	font-size: 0.95rem;
	font-weight: 400;
}

.footer-list-link:hover {
	color: #ee0008;
	text-decoration: none;
}

.footer-list-link.w--current {
	color: #fff;
	text-decoration: underline;
}

.header-nav-bar-content-wrap {
	display: inline-block;
	float: right;
}

.header-search-form-wrap {
	margin-bottom: 0px;
	padding: 1rem;
}

.footer-signoff-bar {
	margin-top: 0.5rem;
	padding-top: 1rem;
	padding-bottom: 0rem;
	background-color: #000;
	font-size: 0.75rem;
	line-height: 1.25rem;
}

.content-box-title {
	margin-top: 0.5rem;
	margin-bottom: 1.5rem;
	font-family: 'Open Sans Condensed', sans-serif;
	color: #000;
	font-size: 2rem;
	line-height: 2.3rem;
	font-weight: 300;
}

.wide-background {
	position: relative;
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding: 2rem 10px;
	background-color: transparent;
}

.wide-background.wide-background-row-1 {
	max-width: 1200px;
	padding-top: 2.25rem;
	padding-bottom: 0rem;
	background-color: transparent;
}

.wide-background.wide-background-inside-page {
	padding: 0rem 40px;
	background-color: #fff;
}

@media screen and (min-width: 991px) {
	.wide-background-inside-page {
		padding: 94px 40px 0px !important;
	}
}

.logo-by-line {
	color: #888;
}

.content-box-header-link {
	color: #000;
	font-weight: 300;
}

.content-box-header-link:hover {
	color: #ee0008;
	text-decoration: none;
}

.news-abstract {
	margin-bottom: 1rem;
	line-height: 1.5rem;
}

.news-feed-article-heading {
	margin-top: 0rem;
	margin-bottom: 0.25rem;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
}

.news-feed-date {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	color: #474848;
	font-size: 0.8rem;
	line-height: 1.1rem;
	font-weight: 400;
}

.news-feed-artcle-header-link:hover {
	color: #ee0008;
	text-decoration: underline;
}

.quick-links-button {
	display: block;
	padding-right: 0.5rem;
	padding-left: 0rem;
	border-top: 1px solid silver;
	border-bottom: 1px none silver;
	background-color: transparent;
	color: #ee0008;
	font-size: 1rem;
	font-weight: 600;
	text-align: left;
}

.quick-links-button:hover {
	color: #ee0008;
	text-decoration: underline;
}

.quick-links-button.last-quicklink-button {
	margin-bottom: 1rem;
	border-top: 1px solid silver;
	border-bottom: 1px solid silver;
}

.video {
	margin-bottom: 1rem;
	text-decoration: none;
}

.footer-map-link {
	padding-left: 25px;
	background-image: url('/20150114134215/assets/images/fontawesome map pin white.svg');
	background-position: 0px 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	color: #fff;
	font-weight: 400;
}

.footer-map-link:hover {
	color: #ee0008;
	text-decoration: none;
}

.footer-paragraph {
	color: #fff;
	font-weight: 400;
	text-align: center;
	display: block;
}

.footer-paragraph h1 {
	display: inline;
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-size: inherit;
	padding: 0px;
	margin: 0px;
	font-family: inherit;
}

.footer-paragraph.fax-text {
	padding-left: 28px;
	background-image: url('/20150114134215/assets/images/printer-fax-white.svg');
	background-position: 0px 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
}

.email-link {
	display: block;
	padding-left: 28px;
	background-image: url('/20150114134215/assets/images/envelope-white.svg');
	background-position: 0px 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	color: #fff;
	font-weight: 400;
}

.email-link:hover {
	color: #ee0008;
	text-decoration: none;
}

.phone-link {
	padding-left: 28px;
	background-image: url('/20150114134215/assets/images/fontawesome phone white.svg');
	background-position: 0px 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	color: #fff;
	font-weight: 400;
}

.phone-link:hover {
	color: #ee0008;
	text-decoration: none;
}

.social-icons-wrap.social-icons-wrap-header {
	margin-right: 1rem;
	margin-bottom: 0.25rem;
	float: right;
}

.newsletter-signup-button {
	display: inline-block;
	height: 28px;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	padding-top: 2px;
	padding-right: 24px;
	padding-bottom: 2px;
	border-left: 1px none #000;
	background-color: #fff;
	background-image: url('/20150114134215/assets/images/arrow bar_808080-gray.svg');
	background-position: 100% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	font-size: 0.85rem;
	font-weight: 600;
}

.newsletter-signup-button {
	margin-left: 1rem;
}

.newsletter-signup-button:hover {
	padding-right: 24px;
	background-color: transparent;
	background-position: 100% 50%;
	background-size: cover;
	color: #fff;
	text-decoration: none;
}

.newsletter-div {
	position: relative;
	margin-right: 0.5rem;
	float: right;
}

.slider-widget {
	height: 550px;
	background-color: transparent;
}

.slider-mask {
	height: 100%;
}

.slide-1 {
	background-image: none;
}

.slide-2 {
	background-image: url('/20150114134215/assets/images/elite-start-services-hero.jpg');
	background-position: 50% 50%;
	background-size: 480px;
	background-repeat: no-repeat;
}

.slider-caption-div {
	position: relative;
	top: 50%;
	right: 0px;
	bottom: 0px;
	max-height: 398px;
	max-width: 45%;
	min-height: 128px;
	min-width: 45%;
	padding: 1.5rem 36px 3rem;
	float: right;
	background-color: hsla(0, 0%, 100%, 0.88);
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
}

.slider-header {
	margin-top: 0rem;
	color: #000;
	line-height: 2.8rem;
	font-weight: 300;
}

.slider-container {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	display: block;
	height: 100%;
}

.slider-button {
	position: relative;
	left: 50%;
	display: none;
	background-color: #d81118;
	-webkit-transform: translate(-50%, 0px);
	-ms-transform: translate(-50%, 0px);
	transform: translate(-50%, 0px);
	font-weight: 600;
	text-transform: uppercase;
}

.more-text-arrow-link {
	position: absolute;
	left: 0px;
	bottom: 0px;
	display: inline-block;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	margin-left: 10px;
	padding-right: 18px;
	background-position: 100% 50%;
	background-size: 14px 14px;
	background-repeat: no-repeat;
}

.more-text-arrow-link:hover {
	background-image: url('/20150114134215/assets/images/arrow triangle_skinny_808080-gray.svg');
	background-position: 100% 50%;
	background-size: 14px 14px;
	background-repeat: no-repeat;
	color: grey;
	text-decoration: none;
}

.more-text-arrow-link.more-text-arrow-link-slider {
	left: auto;
	right: 10%;
	bottom: 0px;
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	float: right;
}

.more-text-arrow-link.more-text-arrow-link-slider:hover {
	color: grey;
	text-decoration: none;
}

.more-text-arrow-link.more-row-1 {
	left: 10px;
	margin-bottom: 1rem;
}

.more-text-arrow-link.more-row-1:hover {
	color: gray;
	text-decoration: none;
}

.content-box-paragraph {
	clear: both;
}

.slide-nav {
	padding-top: 13px;
	background-color: hsla(0, 0%, 100%, 0.2);
	font-size: 0.8rem;
}

.slider-image-div-block {
	height: 100%;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slider-image-div-block.slide-1-image {
	background-position: 50% 0%;
}

.slider-image-div-block.slide-2-image {
	background-size: cover;
}

.slider-image-div-block.slide-3-image {
	background-size: cover;
}

.slider-caption-paragraph {
	max-height: 120px;
}

.top-workplaces {
	text-align: left;
}

.top-workplaces-image {
	max-height: 100px;
}

.right-signoff-column {
	padding-right: 20px;
}

.testimonial-byline {
	font-style: italic;
}

.back-to-top-div {
	margin-top: 1rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
	border-top: 1px none gray;
	border-bottom: 1px none gray;
	background-color: #0e0e0e;
	color: #fff;
	text-align: center;
}

.back-to-top-link {
	display: inline;
	padding-right: 26px;
	background-image: url('/20150114134215/assets/images/arrow medium up-white.svg');
	background-position: 100% 50%;
	background-size: 14px;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
}

.back-to-top-link:hover {
	background-size: 14px 14px;
	color: #ee0008;
	text-decoration: none;
}

.row-1-column {
	padding-bottom: 2.5rem;
	background-color: #fff;
}

.colored-content-box {
	position: relative;
	padding: 0.5rem 20px 2.5rem;
	border-top: 2px solid #ee0008;
	background-color: #fff;
}

.right-sidebar-icon-div {
	display: inline;
	margin-right: 0.25rem;
}

.right-sidebar-icon {
	padding-bottom: 3px;
}

.no-sidebars-wrap {
	padding: 1.5rem 120px 2rem;
}

.inside-content-nested-wrap {
	padding-top: 0.5rem;
}

.left-nav-top-rule {
	height: 2px;
	background-color: #d81118;
}

.field-label {
	color: #000;
	font-size: 0.95rem;
}

.text-field {
	font-size: 0.87rem;
}

.session-text {
	color: #000;
	font-weight: 600;
	text-align: left;
}

.session-text.error-message {
	padding: 20px;
	background-color: #fcc;
}

.session-text.success-message {
	background-color: #9eeb98;
}

html.w-mod-js *[data-ix="mobile-dropdown"] {
	display: none;
}

@media (max-width: 991px) {
	.nav-logo-block {
		margin-top: 0rem;
		margin-left: 0.75rem;
	}

	.nav-menu {
		width: 100%;
		margin-top: 80px;
		margin-bottom: 0px;
		padding-top: 0rem;
		padding-bottom: 5px;
		border-top: 3px solid #000;
		border-bottom: 1px solid rgba(0, 0, 0, 0.25);
		background-color: #474848;
	}

	.nav-link {
		width: 100%;
		margin-bottom: 1px;
		padding-left: 1.25rem;
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
		color: #fff;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.nav-link:hover {
		border-bottom-style: solid;
		border-bottom-color: rgba(237, 28, 36, 0);
		background-color: #141414;
		box-shadow: none;
		color: #fff;
	}

	.nav-link.w--current {
		border-bottom-style: none;
		background-color: #000;
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
		color: #fff;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.nav-link.w--current:hover {
		background-color: rgba(0, 0, 0, 0.35);
		color: #fff;
	}

	.nav-link.dropdown-toggle {
		width: 20%;
		float: right;
	}

	.nav-link.desktop-dropdown-link {
		padding-left: 1.25rem;
		background-image: url('/20150114134215/assets/images/icon_arrow2_right_wht.svg');
		background-position: 96% 50%;
		background-size: 20px;
		background-repeat: no-repeat;
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
	}

	.nav-link.desktop-dropdown-link:hover {
		border-bottom-color: rgba(238, 0, 8, 0);
	}

	.nav-menu-button {
		width: 4.5rem;
		height: 5rem;
		padding: 0px;
		background-color: transparent;
		box-shadow: -1px 0 0 0 rgba(0, 0, 0, 0.5);
		color: #000;
		text-align: center;
	}

	.nav-menu-button:hover {
		background-color: #d81118;
	}

	.nav-menu-button.w--open {
		background-color: #000;
	}

	.nav-dropdown-list {
		display: none;
		margin-bottom: 0.75rem;
		padding: 0rem;
		float: left;
		background-color: gray;
		box-shadow: none;
	}

	.nav-dropdown-list.w--open {
		padding: 0rem 0rem 5px;
		border-top: 2px solid #000;
		border-bottom: 1px solid rgba(0, 0, 0, 0.25);
		background-color: rgba(0, 0, 0, 0.2);
	}

	.nav-dropdown-link {
		padding: 0.75rem 1rem;
		border-top: 0px none transparent;
		border-bottom: 1px solid rgba(0, 0, 0, 0.09);
		color: #fff;
	}

	.nav-dropdown-link:hover {
		background-color: rgba(0, 0, 0, 0.6);
		text-decoration: none;
	}

	.section.footer-section {
		padding-top: 1rem;
	}

	.container.container-inside-page {
		padding-right: 10px;
		padding-left: 10px;
	}

	.cb-row.cb-row-1 {
		margin-bottom: 1rem;
	}

	.column.row-1-column {
		padding-right: 5px;
		padding-bottom: 1rem;
		padding-left: 5px;
	}

	.inside-page-row {
		padding-top: 0.25rem;
	}

	.inside-page-left-column {
		display: none;
	}

	.inside-page-content-column {
		padding-right: 0px;
	}

	.inside-page-nested-row {
		padding-top: 0.5rem;
	}

	.inside-page-nested-content-column {
		margin-bottom: 2rem;
		padding-bottom: 0rem;
	}

	.inside-page-right-column-section-wrap {
		margin-bottom: 0rem;
		margin-left: 0px;
	}

	.content-box-image-link {
		width: 100%;
		height: 160px;
		margin-bottom: 1rem;
		float: left;
	}

	.column-content-wrap.social-media-wrap {
		margin-right: 0px;
		margin-left: 0px;
	}

	.column-content-wrap.navigation-column {
		padding-right: 30%;
	}

	.header-container {
		display: block;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
	}

	.header-search-wrap {
		min-width: 100%;
		margin-bottom: 0rem;
	}

	.sidebar-search-form-wrap {
		margin-bottom: 10px;
	}

	.nav-menu-button-icon {
		margin-top: 1rem;
		font-size: 2rem;
		line-height: 2rem;
	}

	.nav-menu-button-text {
		font-size: 0.875rem;
		line-height: 1rem;
		font-weight: 600;
		text-transform: uppercase;
	}

	.content-box-title {
		margin-bottom: 1.25rem;
	}

	.wide-background {
		padding-top: 1.75rem;
		padding-bottom: 2rem;
	}

	.wide-background.wide-background-row-1 {
		padding-top: 2rem;
	}

	.wide-background.wide-background-inside-page {
		padding-right: 30px;
		padding-bottom: 0rem;
		padding-left: 30px;
	}

	.news-abstract {
		font-size: 0.95rem;
		line-height: 1.45rem;
	}

	.news-feed-article-heading {
		font-size: 0.95rem;
		line-height: 1.45rem;
	}

	.quick-links-button {
		font-size: 0.95rem;
		line-height: 1.45rem;
	}

	.social-icons-wrap.social-icons-wrap-header {
		margin-top: 0.75rem;
		margin-right: 0.25rem;
	}

	.newsletter-signup-button {
		padding-right: 15px;
		background-color: gray;
		background-image: none;
	}

	.newsletter-signup-button:hover {
		padding-right: 15px;
		background-color: #fb0303;
	}

	.newsletter-div {
		margin-top: 0.75rem;
		margin-right: 1.5rem;
	}

	.slider-widget {
		height: 500px;
	}

	.slider-caption-div {
		max-width: 50%;
		padding: 1.25rem 28px 3rem;
	}

	.slider-header {
		margin-top: 0rem;
	}

	.slider-container {
		position: absolute;
	}

	.more-text-arrow-link.more-text-arrow-link-slider {
		right: 8%;
	}

	.more-text-arrow-link.more-row-1 {
		left: 5px;
	}

	.content-box-paragraph {
		font-size: 0.95rem;
		line-height: 1.45rem;
	}

	.slider-image-div-block.slide-2-image {
		background-image: url('/20150114134215/assets/images/Tablet and Chart.jpg');
		background-size: cover;
	}

	.back-to-top-link {
		background-size: auto 14px;
	}

	.colored-content-box {
		padding-right: 15px;
		padding-left: 15px;
	}

	.no-sidebars-wrap {
		margin-right: -10px;
		margin-left: -10px;
		padding-top: 0.25rem;
		padding-right: 0px;
		padding-left: 0px;
	}

	.footer-tel-link {
		display: inline !important;
		font-weight: 400;
	}

	.footer-paragraph {
		line-height: 2.1;
	}
}

@media (max-width: 767px) {
	h1 {
		margin-bottom: 0.75rem;
		font-size: 2.2rem;
		line-height: 2.6rem;
	}

	.nav-logo-block {
		margin-left: 20px;
		padding-left: 0px;
	}

	.container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.cb-row.cb-row-1 {
		margin-bottom: 0rem;
		padding-bottom: 0rem;
		border-bottom: 1px none #000;
	}

	.column {
		padding: 0.75rem 20px 3.5rem;
		border-bottom: 1px solid silver;
	}

	.column.footer-column {
		padding-right: 10px;
		padding-bottom: 0.254rem;
		padding-left: 10px;
		border-bottom: 1px none #000;
	}

	.column.left-signoff-column {
		padding-bottom: 1.25rem;
		border-bottom: 1px none #000;
	}

	.footer-signoff-bar {
		padding-top: 1.75rem;
	}

	.column.center-signoff-column {
		padding-bottom: 0.25rem;
		border-bottom: 1px none #000;
	}

	.column.twitter-column {
		border-bottom: 1px none #000;
	}

	.column.row-1-column {
		border-bottom: 1px none #000;
	}

	.content-box-image-link {
		width: 100%;
		height: 220px;
		float: none;
		background-size: cover;
	}

	.column-content-wrap.navigation-column {
		padding-right: 15%;
	}

	.footer-signoff-row {
		text-align: center;
	}

	.footer-grip-link {
		float: none;
	}

	.footer-signoff-text {
		display: block;
	}

	.footer-signoff-link-wrap {
		display: block;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	.footer-social-icon-link {
		margin-right: 1rem;
		margin-left: 0rem;
	}

	.inside-page-intro {
		font-size: 1.15rem;
		line-height: 1.65rem;
	}

	.wide-background {
		padding-top: 0.5rem;
		padding-right: 0px;
		padding-left: 0px;
		box-shadow: none;
	}

	.wide-background.wide-background-row-1 {
		padding-top: 1rem;
		padding-bottom: 1rem;
		box-shadow: none;
	}

	.wide-background.wide-background-inside-page {
		padding-right: 20px;
		padding-left: 20px;
	}

	.news-abstract {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.news-feed-article-heading {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.quick-links-button {
		padding-left: 0rem;
		border-top: 1px solid silver;
		border-bottom: 1px none #000;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.quick-links-button.last-quicklink-button {
		margin-bottom: 1rem;
		border-top: 1px solid silver;
		border-bottom: 1px solid silver;
	}

	.social-icons-wrap.social-icons-wrap-header {
		margin-left: 0.25rem;
		float: left;
	}

	.newsletter-div {
		margin-left: 20px;
		float: left;
		clear: left;
	}

	.slider-widget {
		height: 460px;
	}

	.slide-1 {
		height: 100%;
	}

	.slider-caption-div {
		position: absolute;
		left: 0px;
		top: auto;
		right: 0px;
		bottom: 0px;
		display: block;
		width: 100%;
		height: 240px;
		max-height: none;
		max-width: 100%;
		min-height: 0px;
		min-width: 0%;
		margin-right: auto;
		margin-left: auto;
		float: left;
		background-color: #fff;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.slider-header {
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	.more-text-arrow-link {
		margin-bottom: 1.5rem;
		margin-left: 20px;
	}

	.more-text-arrow-link.more-row-1 {
		left: -5px;
	}

	.content-box-paragraph {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.slide-nav {
		left: 0px;
		top: 33px;
		right: 0px;
		display: block;
	}

	.slide-nav.slider-caption-paragraph {
		top: -58px;
		font-size: 0.8rem;
	}

	.slider-image-div-block {
		height: 300px;
	}

	.slider-caption-paragraph {
		font-size: 1rem;
	}

	.top-workplaces {
		text-align: center;
	}

	.no-sidebars-wrap {
		padding-right: 10px;
		padding-left: 10px;
	}
}

@media (max-width: 479px) {
	h1 {
		font-size: 2rem;
		line-height: 2.4rem;
	}

	h6 {
		color: #000;
	}

	p {
		color: #000;
	}

	.nav-logo-block {
		width: 244px;
		margin-top: 0.7rem;
		margin-left: 8px;
	}

	.nav-link {
		font-size: 0.95rem;
	}

	.nav-link.w--current {
		font-size: 0.95rem;
	}

	.nav-menu-button {
		width: 4rem;
	}

	.column {
		padding-right: 10px;
		padding-left: 10px;
		border-bottom-width: 1px;
		border-bottom-color: silver;
	}

	.column.footer-column {
		padding-top: 0.25rem;
		padding-bottom: 0rem;
	}

	.column.left-signoff-column {
		border-bottom: 1px none #000;
	}

	.column.center-signoff-column {
		border-bottom: 1px none #000;
	}

	.split-50 {
		width: 100%;
		margin-bottom: 1rem;
	}

	.content-box-image-link {
		height: 160px;
	}

	.column-content-wrap {
		margin-bottom: 1rem;
	}

	.column-content-wrap.navigation-column {
		padding-right: 0%;
	}

	.footer-signoff-bar {
		margin-top: 0rem;
	}

	.wide-background.wide-background-inside-page {
		padding-right: 15px;
		padding-left: 15px;
	}

	.news-feed-artcle-header-link {
		font-size: 0.95rem;
		line-height: 1.45rem;
	}

	.quick-links-button {
		font-size: 0.95rem;
		line-height: 1.45rem;
	}

	.newsletter-div {
		margin-left: 0.75rem;
		float: left;
	}

	.slider-widget {
		height: 506px;
	}

	.slide-2 {
		background-image: none;
	}

	.slider-caption-div {
		height: 296px;
		padding-right: 20px;
		padding-bottom: 3rem;
		padding-left: 20px;
	}

	.slider-header {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.more-text-arrow-link {
		margin-left: 10px;
	}

	.more-text-arrow-link.more-row-1 {
		left: 5px;
	}

	.content-box-paragraph {
		font-size: 0.95rem;
		line-height: 1.45rem;
	}

	.slide-nav {
		left: 0px;
		top: -92px;
		right: 0px;
	}

	.slide-nav.slider-caption-paragraph {
		top: -105px;
		font-size: 0.7rem;
	}

	.slider-image-div-block {
		height: 220px;
	}

	.slider-image-div-block.slide-2-image {
		background-image: url('/20150114134215/assets/images/Tablet and Chart.jpg');
		background-size: cover;
		background-repeat: no-repeat;
	}

	.slider-caption-paragraph {
		max-height: 144px;
	}

	.top-workplaces {
		text-align: center;
	}

	.no-sidebars-wrap {
		padding-right: 10px;
		padding-left: 10px;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/* breakpoints */
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 992px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 768px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/*custom breakpoints*/
[data-breakpoints="mobile-only"] {
	display: none !important;
}

@media screen and ( max-width: 479px ) {
	[data-breakpoints="mobile-only"] {
		display: block !important;
	}
}

.homepage_banner_heading {
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-family: 'Open Sans Condensed', sans-serif;
	color: #30409a;
	font-size: 2rem;
	line-height: 2.5rem;
	font-weight: 700;
}

@media screen and ( max-width: 767px ) {
	.homepage_banner_heading {
		font-size: 1.5rem;
		line-height: 1.9rem;
		margin-top: 0.5rem;
	}
}
