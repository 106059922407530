/****************** Inline Styles *****************/
/*************************************************/
body {
	-webkit-font-smoothing: antialiased;
}
/* --- Increase the max-width for full view --- */
@media screen and (min-width: 992px) {
	.w-container {
		max-width: 1150px;
	}
}
/* CHANGE THE STYLE OF THE SLIDER PAGINATION */
.w-slider-nav > div {
	border: white 1px solid;
	background: none;
}

.w-slider-nav > div.w-active {
	border: white 1px solid;
	background-color: red;
}
/* HIDE SLIDER LEFT-RIGHT ARROWS AT DEFINED VIEWPORT WIDTH */
@media all and (max-width: 1300px) {
	.slider-arrow-link-bar {
		display: none;
	}
}
/* EQUAL HEIGHT HOMEPAGE CONTENT BOX COLUMNS - Row 1 */
@media screen and (min-width: 768px) {
	.row-1-column {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		overflow: hidden;
		align-items: stretch;
	}
}
/* EQUAL HEIGHT HOMEPAGE CONTENT BOX COLUMNS - Row 2 */
@media screen and (min-width: 768px) {
	.cb-row {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		overflow: hidden;
		align-items: stretch;
	}
}
/* CONTROL PHONE NUMBER COLOR */
a[href^=tel] {
	color: #ffffff;
	text-decoration: none;
}
/* Text selection - Mozilla based browsers */
::-moz-selection {
	background-color: #808080;
	color: #ffffff;
}


body {
	font-size: 0.95rem;
	font-weight: 400;
	line-height: 1.5rem;
}

.page-wrapper {
	color: #000;
}

/****************** Print Styles *****************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/****************** Header ***********************/
/*************************************************/
.logo {
	transition: width 0.7s ease;
}

.desktop-navigation .nav-link.w--current {
	color: black;
	box-shadow: none;
}

.desktop-navigation .nav-link.selected {
	color: #ee0008;
	font-weight: 600;
}

.desktop-navigation .nav-dropdown:hover .nav-dropdown-list {
	display: block;
}

.nav-section-header {
	position: fixed;
	width: 100%;
	height: 94px;
	transition: all 0.4s ease;
}

.newsletter-signup-button.w--current {
	color: white;
}

.desktop-navigation .nav-dropdown:hover .nav-link {
	border-bottom-color: #ee0008;
	color: #d81118;
	font-weight: 600;
	text-decoration: none;
}

.desktop-navigation .nav-dropdown-list .nav-dropdown-link.w--current {
	background-color: #d81118;
	color: #ffffff;
	text-decoration: none;
}

@media (max-width: 991px) {
	.nav-section-header {
		position: relative;
		width: 100%;
		height: auto;
	}

	.section.cb1,
	.content-box-section-1 {
		top: 0px;
	}
}

@media (max-width: 340px) {
	.nav-logo-block {
		width: 210px;
	}
}

/****************** Mobile navigation ****************/
/*****************************************************/
.mobile-navigation-menu {
	margin-top: 0px;
	background-color: #474848;
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);
	border-top: 0px;
	display: none !important;
	width: 80%;
}

.mobile-navigation-menu.mm-opened {
	display: block !important;
}

.mobile-navigation-menu .mm-panel {
	padding-left: 0px;
	padding-right: 0px;
}

.mobile-navigation-menu ul {
	margin-left: 0px;
	margin-right: 0px;
}

.mm-panels > .mm-panel > .mm-listview {
	margin-left: 0px;
	margin-right: 0px;
}

#mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults)::after {
	border-style: none;
}

.mobile-navigation-menu li a,
.mobile-navigation-menu li span.nav-a {
	background-position: 96% 50%;
	background-repeat: no-repeat;
	background-size: 20px auto;
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
	padding-left: 1.25rem;
	color: white;
	font-size: 1rem;
	line-height: 1.5rem;
	margin-bottom: 0px;
	font-family: "Open Sans", sans-serif;
	text-transform: uppercase;
	font-weight: 600;
}

.mobile-navigation-menu li:hover {
	background-color: #141414;
	color: white;
}

.mobile-navigation-menu li.active a,
.mobile-navigation-menu li.active span.nav-a {
	background-color: #000;
	border-bottom-style: none;
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	color: white;
}

.mobile-navigation-menu li.active a.mm-fullsubopen {
	background-color: transparent;
}

.mm-menu .mm-listview > li .mm-next::after,
.mm-menu .mm-listview > li .mm-arrow::after {
	border-color: white;
}

.mm-prev::before,
.mm-next::after,
.mm-arrow::after {
	border: 3px solid transparent;
	height: 9px;
	width: 9px;
}

.mm-listview .mm-next {
	width: 60px;
}

.mm-next::after,
.mm-arrow::after {
	right: 26px;
	border-left: medium none;
	border-top: medium none;
}

.mm-listview .mm-next + a,
.mm-listview .mm-next + span {
	margin-right: 60px;
}

.mm-listview > li .mm-next,
.mm-listview > li .mm-next::before {
	border-color: rgba(0, 0, 0, 0.2);
}

.mm-listview > li.active .mm-next,
.mm-listview > li.active .mm-next::before {
	border-color: transparent;
}

.mm-listview > li > a,
.mm-listview > li > span {
	white-space: normal;
}

.mm-listview > li:not(.mm-divider)::after {
	border-bottom-width: 0;
}

.mm-prev::before {
	border-bottom: medium none;
	border-right: medium none;
}

.mm-menu .mm-navbar > *,
.mm-menu .mm-navbar a {
	color: white;
	text-transform: uppercase;
	font-size: 1rem;
	line-height: 1.2rem;
}

.mm-menu .mm-navbar .mm-btn::before,
.mm-menu .mm-navbar .mm-btn::after {
	border-color: white;
}

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next),
.mm-menu .mm-listview > li.mm-selected > span {
	background-color: black;
}

@media (min-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-opened {
		display: none !important;
	}
}

/****************** Homepage  ***********************/
/****************************************************/
.section.cb1,
.homepage-section {
	width: 100%;
	min-height: 200px;
}

@media (min-width: 991px) {
	.section.cb1 {
		padding-top: 94px;
	}
}

.homepage-section .colored-content-box .fluid-width-video-wrapper {
	height: 200px;
}

.homepage-section .content-box-section-1 .colored-content-box,
.homepage-section .column.row-1-column .colored-content-box {
	width: 100%;
	min-height: 100px;
}

.homepage-section .cb-row a {
	color: #ee0008;
}

.homepage-section .cb-row a:hover {
	color: #ee0008;
	text-decoration: underline;
}

.homepage-section .content-box-title a,
.homepage-section .content-box-title a:hover {
	color: #000;
	font-weight: 300;
	font-family: "Open Sans Condensed", sans-serif;
	font-size: 2rem;
	line-height: 2.3rem;
	margin-bottom: 1.5rem;
	margin-top: 0.5rem;
	text-decoration: none;
}

.homepage-section .content-box-title a:hover {
	color: #ee0008;
}

.homepage-section .cb-row ul {
	margin: 0px;
	padding: 0px;
}

.homepage-section .cb-row ul li {
	background-color: transparent;
	border-top: 1px solid silver;
	color: #d81118;
	display: block;
	font-size: 1rem;
	font-weight: 600;
	padding-left: 0rem;
	padding-right: 0.5rem;
	text-align: left;
	padding-top: 9px;
	padding-bottom: 9px;
}

.homepage-section .cb-row ul li:last-of-type {
	border-bottom: 1px solid silver;
}

.homepage-section .cb-row .more-text-arrow-link {
	text-transform: uppercase;
}

.homepage-section .cb-row .more-text-arrow-link:hover {
	color: grey;
	text-decoration: none;
}

@media (max-width: 991px) {
	.cb-content a,
	.cb-content p {
		font-size: 0.95rem;
		line-height: 1.45rem;
	}
}

@media (max-width: 767px) {
	.cb-content a,
	.cb-content p {
		font-size: 1rem;
		line-height: 1.5rem;
	}
}

@media (max-width: 479px) {
	.cb-content a,
	.cb-content p {
		font-size: 0.95rem;
		line-height: 1.45rem;
	}
}

/****************** Slideshow  ***********************/
/****************************************************/
.slider-container {
	position: relative;
	z-index: 100;
}

.slider-image-div-block {
	position: absolute;
	top: 0;
	width: 100%;
}

.more-text-arrow-link.more-text-arrow-link-slider {
	text-transform: uppercase;
}

.slider-caption-div {
	min-height: 215px;
}

.slide-nav {
	padding-top: 0px;
}

.slide-2,
.slide-1,
.slide-3,
.slide-4,
.slide-5 {
	background: none;
}

.cms-resp-slideshow-paging span {
	cursor: pointer;
	display: inline-block;
	height: 13px;
	margin: 0 8px 0.5em;
	position: relative;
	transition: background-color 100ms ease 0s, color 100ms ease 0s;
	width: 13px;
	background-color: rgba(255, 255, 255, 0.4);
	border: 1px solid white;
	font-size: 0;
	line-height: 0;
	top: -3px;
}

.cms-resp-slideshow-paging span.active {
	background-color: red;
	border: 1px solid white;
}

.slider-caption-paragraph {
	color: #474848;
	font-size: 0.95rem;
	font-weight: 400;
	line-height: 1.5rem;
	display: inline;
}

.slider-header {
	font-family: "Open Sans Condensed", sans-serif;
	font-size: 2.25rem;
	margin-bottom: 1rem;
}

@media (max-width: 767px) {
	.slider-header {
		font-size: 1.75rem;
		line-height: 2.25rem;
		margin-bottom: 0.75rem;
	}

	.slider-caption-paragraph {
		font-size: 1rem;
	}

	.slider-caption-paragraph h1 {
		font-size: 1.5rem;
		line-height: 1.9rem;
		margin-top: 0.5rem;
	}
}

@media (max-width: 479px) {
	.slider-header {
		font-size: 1.5rem;
		line-height: 2rem;
	}
}

/****************** Inside Page ***********************/
/*****************************************************/
.header-content {
	margin-bottom: 20px;
	padding-bottom: 10px;
}

.inside-page-nested-row {
	padding-top: 8px;
	min-height: 600px;
	width: 100%;
}

.left-nav-list-item-link.selected {
	color: #ee0008;
	font-weight: 600;
}

.left-nav-list-item-link.nested-left-nav-link.selected {
	background-position: 0 50%;
	background-repeat: no-repeat;
	background-size: 14px 14px;
	color: #ee0008;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.left-nav-list.nested-left-nav-list {
	box-shadow: none;
}

.left-nav-list-item-link.nested-left-nav-link:hover {
	color: #ee0008;
}

.breadcrumbs-list-divider {
	margin-left: 0.45rem;
	margin-right: 0.45rem;
}

.breadcrumbs-list-item {
	margin-right: 0px;
}

#latestBread {
	color: black;
}

.breadcrumbs-list-item .w--current {
	color: #d81118;
}

.w-container .w-row.no-columns {
	margin-right: 0px;
	margin-left: 0px;
}

.w-container .w-row.no-columns .inside-page-content-column {
	padding: 0rem 120px 2rem;
}

.inside-page-content-column {
	min-height: 450px;
	margin-bottom: 50px;
}

.inside-page-left-column {
	margin-bottom: 100px;
}

.breadcrumbs-list-item.mobile {
	display: none;
}


@media (max-width: 991px) {
	.w-container .w-row.no-columns .inside-page-content-column {
		margin-left: -10px;
		margin-right: -10px;
		padding-top: 0rem;
		padding: 0rem 20px 2rem;
	}
}

@media (max-width: 479px) {
	.w-container .w-row.no-columns .inside-page-content-column {
		margin-left: -10px;
		margin-right: -10px;
		padding: 0rem 10px 2rem;
	}

	.breadcrumbs-list-item.desktop {
		display: none;
	}

	.breadcrumbs-list-item.mobile {
		display: inline;
	}
}

/****************** Right Sidebar ***********************/
/*******************************************************/
.inside-page-nested-right-column .box {
	border-top: 2px solid #d81118;
	margin-bottom: 1rem;
	margin-left: 20px;
	padding: 0.75rem 0.5rem 0.25rem;
}

.inside-page-nested-right-column .box .title h4,
.inside-page-nested-right-column .box h4,
.inside-page-nested-right-column .box h2,
.inside-page-nested-right-column .box h3 {
	margin-bottom: 0.5rem;
	font-weight: 600;
	color: #000;
	font-size: 1rem;
	line-height: 1.5rem;
	margin-top: 0rem;
}

.inside-page-nested-right-column .box td,
.inside-page-nested-right-column .box.documents .content td {
	padding-right: 7px;
	vertical-align: top;
	border-bottom: 1px solid #e9e9e9;
	font-size: 0.95rem;
	line-height: 1.45rem;
	padding-bottom: 0.55rem;
	padding-top: 0.6rem;
}

.inside-page-nested-right-column .box.documents .content td:first-child,
.inside-page-nested-right-column .contentbox_item .contentbox_item_image {
	width: 24px;
}

.inside-page-nested-right-column .box img,
.inside-page-nested-right-column .box.documents .content td img,
.inside-page-nested-right-column .contentbox_item .contentbox_item_image img {
	max-width: none;
}

.inside-page-nested-right-column .box.links .content p,
.inside-page-nested-right-column .box.archive .archive_links {
	border-bottom: 1px solid #e9e9e9;
	font-size: 0.95rem;
	line-height: 1.45rem;
	padding-bottom: 0.5rem;
	padding-top: 0.5rem;
	margin: 0px;
}

.inside-page-nested-right-column .box.archive .archive_links {
	display: inline-block;
	width: 100%;
}

.inside-page-nested-right-column .box.tools .content input[type="text"],
.inside-page-nested-right-column input[type="text"],
.inside-page-nested-right-column select,
.inside-page-nested-right-column textarea {
	border: 1px solid silver;
	border-radius: 0;
	font-size: 0.85rem;
	margin-bottom: 0.5rem;
	min-height: 2.34rem;
	padding: 0.53rem 0.75rem;
	color: #333333;
	display: block;
	vertical-align: middle;
	line-height: 1.42857;
	width: 100%;
}

.inside-page-nested-right-column .box.tools .content button.primary {
	line-height: inherit;
	border: 0 none;
	border-radius: 0;
	background-color: black;
	color: white;
	display: inline-block;
	font-size: 0.95rem;
	margin-bottom: 0.25rem;
	margin-right: 20px;
	margin-top: 0.25rem;
	padding: 0.375rem 1rem;
	text-align: center;
	text-decoration: none;
	text-transform: capitalize;
	transition: background-color 200ms ease 0s, color 200ms ease 0s;
}

.inside-page-nested-right-column .box.tools .content p {
	margin-bottom: 0px;
}

.inside-page-nested-right-column .box.poll .content td {
	padding-top: 0px;
	padding-bottom: 0px;
	border-bottom: 0px;
}

.inside-page-nested-right-column .box .archive_links a,
.inside-page-nested-right-column .box .archive_links a.selected {
	padding-bottom: 0px;
}

@media (max-width: 991px) {
	.inside-page-nested-right-column .box.documents table {
		width: 100%;
	}

	.inside-page-nested-right-column .box {
		margin-left: 0px;
	}
}

/****************** Footer ***********************/
/*************************************************/
.back-to-top-link {
	background-size: 14px 14px;
}

.footer-paragraph.desktop {
	display: block;
}

.footer-paragraph.mobile {
	display: none;
}

.footer-list-link.w--current {
	color: white;
}

.footer-list-link.selected {
	color: white;
	text-decoration: underline;
}

.footer-paragraph.adobe {
	margin-top: 15px;
}

span.phone-link:hover {
	color: white;
}

.footer-signoff-link.w--current {
	color: white;
}

.email-link.w--current {
	color: white;
}

#GRIPFooterLogo {
	line-height: 0.6875rem;
	float: right;
	padding-top: 0px !important;
	margin-top: -1px;
}

@media (max-width: 991px) {
	.footer-paragraph.desktop {
		display: none;
	}

	.footer-paragraph.mobile {
		display: block;
	}

	#GRIPFooterLogo {
		float: none;
		margin: 0 auto;
		margin-top: 7px;
	}
}


/****************** Styles ***********************/
/*************************************************/
.button {
	border-bottom: 0px;
}

.cms_buttons .primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.button_primary,
button,
.button,
.homepage-section .cb-row a.button,
.primary,
.button.primary,
.homepage-section .cb-row a.button.primary {
	background-color: black;
	color: white;
	display: inline-block;
	font-size: 0.95rem;
	margin-bottom: 0.25rem;
	margin-right: 20px;
	margin-top: 0.25rem;
	padding: 0.375rem 1rem;
	text-align: center;
	text-decoration: none;
	text-transform: capitalize;
	transition: background-color 200ms ease 0s, color 200ms ease 0s;
	border: 0 none;
	border-radius: 0;
	cursor: pointer;
	line-height: inherit;
	-webkit-appearance: none;
}

.cms_buttons .primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.button_primary:hover,
button:hover,
.button:hover,
.homepage-section .cb-row a.button:hover,
.primary:hover,
.button.primary:hover,
.homepage-section .cb-row a.button.primary:hover {
	background-color: #d81118;
	color: #ffffff;
}

.cms_buttons .primary:active,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:active,
.button_primary:active,
button:active,
.button:active,
.homepage-section .cb-row a.button:active,
.primary:active,
.button.primary:active,
.homepage-section .cb-row a.button.primary:active {
	background-color: #d81118;
	color: #ffffff;
}

.cms_buttons .secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.button_secondary,
.button.secondary,
.homepage-section .cb-row a.button.secondary {
	background-color: #707070;
	color: #ffffff;
	display: inline-block;
	font-size: 0.95rem;
	margin-bottom: 0.25rem;
	margin-right: 20px;
	margin-top: 0.25rem;
	padding: 0.375rem 1rem;
	text-align: center;
	text-decoration: none;
	text-transform: capitalize;
	transition: background-color 200ms ease 0s, color 200ms ease 0s;
	border: 0 none;
	border-radius: 0;
	cursor: pointer;
	line-height: inherit;
	-webkit-appearance: none;
}


.cms_buttons .secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.button_secondary:hover,
.button.secondary:hover,
.homepage-section .cb-row a.button.secondary:hover {
	background-color: #d81118;
	color: #ffffff;
}

.cms_buttons .secondary:active,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:active,
.button_secondary:active,
.button.secondary:active,
.homepage-section .cb-row a.button.secondary:active {
	background-color: #d81118;
	color: #ffffff;
}

.cms_buttons .tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary,
.button_tertiary,
.homepage-section .cb-row a.button_tertiary {
	background-color: #707070;
	color: #ffffff;
	display: inline-block;
	font-size: 0.95rem;
	margin-bottom: 0.25rem;
	margin-right: 20px;
	margin-top: 0.25rem;
	padding: 0.375rem 1rem;
	text-align: center;
	text-decoration: none;
	text-transform: capitalize;
	transition: background-color 200ms ease 0s, color 200ms ease 0s;
	border: 0 none;
	border-radius: 0;
	cursor: pointer;
	line-height: inherit;
	-webkit-appearance: none;
}

.cms_buttons .tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.button_tertiary:hover,
.homepage-section .cb-row a.button_tertiary:hover {
	background-color: #d81118;
	color: #ffffff;
}

.cms_buttons .tertiary:active,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:active,
.button_tertiary:active,
.homepage-section .cb-row a.button_tertiary:active {
	background-color: #d81118;
	color: #ffffff;
}

#message.success {
	background-color: white;
	border: 2px solid #3c5c1f;
	margin-bottom: 20px;
	padding: 10px 20px;
	color: #3c5c1f;
}

#message.success ul,
#message.success li,
#message.success p {
	color: #3c5c1f;
}

#message.error {
	background-color: #ffffff;
	border: 2px solid #df0000;
	margin-bottom: 20px;
	padding: 10px 20px;
	color: #df0000;
}

#message.error ul,
#message.error li,
#message.error p {
	color: #df0000;
}

table.styled {
	background-color: #ffffff;
}

.styled td.tableHeader,
.tableHeader {
	background-color: black;
	color: white;
	font-size: 1.125rem;
	font-weight: normal;
	padding: 10px;
	text-align: left;
	border: medium none;
}

.styled th {
	background-color: #707070;
	color: #ffffff;
	font-size: 1rem;
	font-weight: normal;
	padding: 10px;
	text-align: left;
}

.styled thead th {
	background-color: #000000;
	color: white;
	font-size: 1.125rem;
	font-weight: normal;
	padding: 10px;
	text-align: left;
	border: medium none;
}

.styled td {
	padding: 8px 10px;
	color: #474848;
}

.styled.striped td,
.styled.striped th,
.oddrow td,
.evenrow td {
	border-right: 1px solid whitesmoke;
}

.styled.striped tr:nth-child(even),
.oddrow {
	background: lightgray none repeat scroll 0 0;
}

.styled.striped tr:nth-child(odd),
.evenrow {
	background: white none repeat scroll 0 0;
}

.oddrow td,
.evenrow td {
	padding: 8px 10px;
}

.oddrow td,
.evenrow td {
	padding: 8px 10px;
}

.styled.striped td:last-child,
.styled.striped th:last-child,
.oddrow td:last-child,
.evenrow td:last-child {
	border-right: 0px;
}

.inside-page-nested-content-column input[type="text"],
.inside-page-nested-content-column input[type="password"],
.inside-page-nested-content-column select,
.inside-page-nested-content-column textarea {
	background-color: #ffffff;
	border: 1px solid #cccccc;
	color: #333333;
	font-size: 0.875rem;
	line-height: 1.42857;
	margin-bottom: 10px;
	padding: 6px 12px;
	vertical-align: middle;
	font-size: 0.87rem;
	max-width: 100%;
}

@media (max-width: 991px) {
	.inside-page-nested-content-column input[type="text"],
	.inside-page-nested-content-column input[type="password"],
	.inside-page-nested-content-column select,
	.inside-page-nested-content-column textarea {
		width: 100%;
	}
}

/****************** Content Modules ***************/
/*************************************************/
/* alerts */
#alerts_module {
	padding-left: 0px;
}

#alerts_module.list .title h2 {
	margin-top: 0px;
}

#alerts_module.list .item_divider,
#alerts_module.entity .item_divider {
	margin-bottom: 14px;
	margin-top: 18px;
}

.inside-page-nested-right-column .box #alerts_module_subscribe_form td {
	border-bottom: 0px;
}

#alerts_module_subscribe_form.form .item_divider {
	margin-bottom: 20px;
	margin-top: 20px;
}

#alerts_module_subscribe_form.form .form_item .field label,
#alerts_module_subscribe_form.form .form_item .field span,
#alerts_module_subscribe_form.form .form_item .field input,
#alerts_module_subscribe_form.form .form_item .field select,
#alerts_module_subscribe_form.form .form_item .label,
#alerts_module_subscribe_form.form .form_item.required .label,
#alerts_module_subscribe_form.form .terms label {
	font-size: 0.8125rem;
}

#alerts_module_subscribe_form.form .form_item .field input[name="MobileNumber"] {
	margin-bottom: 0px;
}

.inside-page-nested-right-column .box #alerts_module_subscribe_form td {
	padding-top: 3px !important;
	padding-bottom: 3px !important;
}

/* blog */
@media (max-width: 991px) {
	#blogs_module.cms_list table td {
		display: block;
		width: 100%;
	}

	#module-blogs input,
	#module-blogs input[type="email"],
	#module-blogs .moduleform_field textarea {
		width: 100% !important;
	}
}

/* events */
#events_module.cms_list {
	float: left;
}

#events_module #fullcalendar-controls {
	margin-bottom: 20px;
}

#events_module.cms_list .cms_divider,
#events_module.cms_entity .cms_divider {
	margin-bottom: 18px;
	margin-top: 20px;
}

#events_module.cms_list .cms_title h3,
#events_module.cms_list .cms_description h3,
#events_module.cms_entity .cms_description h2 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .cms_date {
	font-size: 1rem;
	margin-top: 9px;
	margin-bottom: 10px;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required {
	background-color: transparent;
}

#events_module.cms_form .cms_required {
	background-image: none;
}

#events_module.cms_form .cms_required::after {
	content: "*";
	color: #ee0000;
	font-style: italic;
}

#events_module.cms_form .cms_info_req img {
	display: none;
}

#events_module.cms_form .cms_info_req::before {
	content: "*";
	color: #ee0000;
	padding-right: 4px;
	font-style: italic;
}

#events_module.cms_form .cms_divider {
	border-top: none;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		display: inline;
	}

	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		width: 100% !important;
		display: block;
		max-width: 100%;
	}
}

/* faq */
#faqs_module .cms_title h3 {
	margin-top: 0px;
	font-size: 1.55rem;
}

#faqs_module .cms_list_item ul,
#faqs_module .cms_list_subitem ul {
	list-style-type: disc;
	padding-left: 15px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-bottom: 27px;
	margin-top: 29px;
}

#faqs_module.cms_entity .cms_content {
	margin-bottom: 20px;
}

/* files */
.collection.collection--files .field--file--title {
	background-position: 3px;
}

.collection.collection--categories .node--category {
	margin: 0px 0px 0px 0px;
	padding: 15px 0px 10px 0px;
	border-top: 1px solid #e1e3e0;
}

.collection.collection--files {
	border-top: 0px;
}

.collection.collection--files > .node.node--file {
	margin: 0px 0px 0px 0px;
	padding: 15px 0px 15px 0px;
	border-top: 1px solid #e1e3e0;
}

.inside-page-nested-right-column .select2-container.select2-container-multi .select2-choices {
	background-color: #ffffff;
	border: 1px solid silver;
	box-shadow: none;
	border-radius: 0;
	padding: 0px;
}

.inside-page-nested-right-column .select2-container.select2-container-multi .select2-choices .select2-search-field .select2-input {
	max-width: 100%;
}

.inside-page-nested-right-column.select2-container.select2-container-multi {
	width: 100% !important;
}

.inside-page-nested-right-column .filter-row .select2-container {
	width: 100% !important;
}

.inside-page-nested-right-column .filter-row .select2-container .select2-choice {
	background-image: none;
	border: 1px solid silver;
	border-radius: 0;
	height: auto;
	padding-bottom: 4px;
	padding-top: 4px;
}

@media (max-width: 991px) {
	.inside-page-nested-right-column .filter-row.ui-form-buttons .ui-form-field {
		float: left;
		padding-top: 10px;
	}

	.inside-page-nested-right-column .filter-row.ui-form-buttons .ui-form-button:first-child {
		padding-top: 0px;
	}

	.inside-page-nested-right-column .filter-row.ui-form-buttons .ui-form-button:last-child {
		margin-left: 15px;
	}
}

/* forum */
#forums_module #posts .forum_post_rating small:first-child {
	display: inline-block;
	text-align: right;
	width: 80px;
}

#forums_module #boards table,
#forums_module #topics table,
#forums_module #posts table {
	background-color: transparent;
	border: none;
	border-bottom: 1px solid #e1e3e0;
}

#forums_module #boards table h2 a,
#forums_module #topics table h2 a,
#forums_module #posts table h2 a,
#forums_module h2 {
	color: #ee0008;
}

#forums_module #boards td {
	padding-top: 16px;
	padding-bottom: 14px;
	padding-left: 0px;
	padding-right: 5px;
}

#forums_module #topics td,
#forums_module #posts td {
	padding-top: 16px;
	padding-bottom: 16px;
	padding-left: 0px;
	padding-right: 5px;
}

#forums_module #topics tr td.poster_info,
#forums_module #posts tr td.poster_info {
	padding-top: 16px;
}

#forums_module #posts .post_footer {
	background-color: transparent;
}

#forums_module.form .form_item.required .field {
	background: none;
	padding-left: 10px;
}

#forums_module.form .form_item .field {
	padding-left: 10px !important;
}

#forums_module.form .form_item.required > .label::after {
	content: "*";
	color: #ee0000;
	padding-left: 10px;
	font-style: italic;
}

#forums_module #posts table td.actions a.reply span,
#forums_module #posts table td a.reply-with-quote span {
	margin-top: 5px;
}

#forums_module #posts table td.actions a {
	color: #ee0008;
	font-size: 0.8rem;
}

#forums_module #posts .post_quote_title {
	background: #ccc;
}

@media (max-width: 991px) {
	#forums_module h2 {
		margin-top: 15px;
	}

	#forums_module.form .form_item.required > .label::after {
		content: "*";
		color: #ee0000;
		font-style: italic;
	}

	#forums_module.form .form_item .field,
	#forums_module.form .form_item.required .field {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}

	#forums_module #posts .forum_post_rating small:first-child {
		width: 100px;
	}
}

/* jobs */
#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 5px;
	font-size: 1rem;
	margin-bottom: 5px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}


#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-bottom: 9px;
	margin-top: 19px;
}

#jobs_module.cms_form .cms_form_button {
	background-color: black;
	border: 0 none;
	border-radius: 0;
	padding: 8px 10px 6px;
}

#jobs_module.cms_form .cms_form_button:hover {
	background-color: #d81118;
	color: #ffffff;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field {
	background-color: transparent;
}

#jobs_module.cms_form .cms_field {
	padding-top: 12px;
}

#jobs_module.cms_form .cms_required,
#jobs_module.cms_form .cms_non_required img {
	display: none;
}

#jobs_module.cms_form .cms_label label::after {
	content: "*";
	color: #ee0000;
	padding-left: 6px;
	font-style: italic;
}

#jobs_module.cms_form .cms_info_req img {
	display: none;
}

#jobs_module.cms_form .cms_info_req::before {
	content: "*";
	color: #ee0000;
	padding-right: 6px;
	font-style: italic;
}

#jobs_module.cms_form .cms_divider {
	border-top: 0px;
}

#jobs_module div.application-for-employment form div.form-field label {
	font-size: 1rem;
}

#jobs_module div.application-for-employment form div.form-field span small,
#jobs_module div.application-for-employment form div.form-field span label {
	font-size: 0.6875rem;
	font-weight: normal;
}

#jobs_module div.application-for-employment form div.form-field span label {
	margin-top: 0px;
}

#jobs_module div.application-for-employment form div.form-field span.text input,
#jobs_module div.application-for-employment form div.form-field span.select select,
#jobs_module div.application-for-employment form div.form-field span.textarea textarea {
	border: 1px solid #cccccc;
	max-width: 100%;
	font-size: 0.87rem;
	margin-top: 7px;
	padding: 6px 12px;
}

#jobs_module div.application-for-employment form div.form-field span.select select {
	padding-bottom: 6px;
}

#jobs_module div.application-for-employment form div.form-field span textarea,
#jobs_module div.application-for-employment form div.form-field span input,
#jobs_module div.application-for-employment form div.form-field span select,
#jobs_module div.application-for-employment form div.form-field span.x2 select,
#jobs_module div.application-for-employment form div.form-field span.x2 textarea,
#jobs_module div.application-for-employment form div.form-field span.x3 textarea,
#jobs_module div.application-for-employment form div.form-field span.x4 textarea,
#jobs_module div.application-for-employment form div.form-field span.x2 input,
#jobs_module div.application-for-employment form div.form-field span.x3 input,
#jobs_module div.application-for-employment form div.form-field span.x4 input,
#jobs_module div.application-for-employment form div.form-field span.text.x2 {
	width: 100%;
}

#jobs_module div.application-for-employment form .step.n11 div.form-field span label {
	float: none;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field {
		display: block;
		width: 100%;
		padding-top: 6px;
	}

	#jobs_module.cms_form .cms_label {
		display: inline-block;
		text-align: left;
		margin-left: 0px;
		padding-left: 0px;
		width: auto;
	}

	#jobs_module.cms_form .cms_field input[type="text"] {
		width: 100% !important;
	}

	#jobs_module div.application-for-employment div.required_field_information,
	#jobs_module div.application-for-employment div.tabs,
	#jobs_module div.application-for-employment form div.step,
	#jobs_module div.application-for-employment form div.form-fields,
	#jobs_module div.application-for-employment div.tabs ul,
	#jobs_module div.application-for-employment form div.form-field {
		width: 100%;
		display: inline-block;
	}
}


/* links */
#links_module ul {
	padding-left: 15px;
}

/* locations */
#locations_module.list .location-item-name h3 {
	margin-top: 0px;
}

#locations_module.list .location-item .location-item-details .location-item-google-map {
	margin-bottom: 0px;
}

#locations_module.list .location-item.location-item-with-image {
	padding-top: 6px;
}

#locations_module_search_contentbox.contentbox_item .item_field select#MilesRadius {
	display: inline-block;
}

#locations_module_search_contentbox.contentbox_item .item_field input#PostalCode {
	display: inline-block;
}

#locations_module_display_view_contentbox {
	text-align: left;
}

@media (max-width: 991px) {
	#locations_module_search_contentbox.contentbox_item .item_field input#PostalCode,
	#locations_module_search_contentbox.contentbox_item .item_field select.selectBox,
	#locations_module_search_contentbox.contentbox_item .item_field input#Keywords,
	#locations_module_search_contentbox.contentbox_item .item_field select#MilesRadius {
		width: 100% !important;
	}
}

@media (max-width: 767px) {
	#locations_module.list .location-item.location-item-with-image .location-item-image-image {
		margin-bottom: 7px;
	}
}

/* news */
#news_module.cms_list .cms_item_divider {
	margin-bottom: 12px;
	margin-top: 20px;
	display: inline-block;
	width: 100%;
}

#news_module.cms_list .cms_list_item .cms_date h3 {
	font-weight: 600;
	margin-bottom: 0px;
	margin-top: 0px;
}

#news_module.cms_list .cms_list_item .cms_title h3,
#news_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0;
}


#news_module.cms_list .image img {
	max-width: none;
}

@media (max-width: 991px) {
	#news_module.cms_list .cms_list_item {
		padding-top: 0px;
	}

	#news_module.cms_list .cms_list_item table td,
	#news_module.cms_list .cms_list_item.left,
	#news_module.cms_list .cms_list_item.right {
		display: block;
		width: 100%;
		padding-left: 0px;
	}

	#news_module.cms_list .cms_list_item td.description {
		margin-bottom: 25px;
	}

	#news_module.cms_entity .cms_content .image {
		float: none;
		display: block;
		margin-left: 0px;
	}
}

/* newsletter*/
#newsletters_module.list .item_divider,
#newsletters_module.entity .item_divider {
	margin-bottom: 18px;
	margin-top: 23px;
}

#newsletters_module.list .title h3 {
	margin-top: 0px;
}

#newsletters_module.form td {
	padding-bottom: 15px;
}

#newsletters_module.form .required_message {
	margin-bottom: 20px;
}

#newsletters_module.form .form_item.required .field,
#newsletters_module.form .required_message span {
	background: none;
}

#newsletters_module.form .form_item.required .label label {
	display: inline;
}

#newsletters_module.form .form_item.required .label::after {
	color: #ee0000;
	content: "*";
	font-style: italic;
	padding-left: 5px;
}

#newsletters_module.form .required_message span::before {
	color: #ee0000;
	content: "*";
	font-style: italic;
	padding-right: 12px;
}

@media (max-width: 991px) {
	#newsletters_module.form .form_item .label,
	#newsletters_module.form .form_item.required .label {
		display: inline-block;
		text-align: left;
		padding-left: 0px;
		width: auto;
		padding-bottom: 4px;
	}

	#newsletters_module.form .form_item.required .field,
	#newsletters_module.form .form_item .field {
		display: block;
		background: none;
		padding-left: 0px;
	}

	#newsletters_module.form .form_item.required .field input[type="text"] {
		width: 100% !important;
	}
}

/* partners */
#partners_module.cms_list .cms_list_item {
	margin-bottom: 22px;
	margin-top: 22px;
}

#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 17px;
}

#partners_module.cms_list .cms_list_item table td.image img {
	max-width: none;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* photo albums */
#photoalbums_module.cms_list .cms_list_item .cms_image {
	padding-right: 17px;
}

#photoalbums_module.cms_list .cms_item_divider {
	margin-bottom: 24px;
	margin-top: 24px;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item .cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
		margin-top: 4px;
	}
}

/* polls */
#pollForm .ui-form-field {
	display: block;
	float: none;
	margin-bottom: 10px;
}

@media (max-width: 991px) {
	#polls #pollResult .line {
		width: 100% !important;
	}
}

/* products */
#products_module.cms_list h3 {
	margin-top: 0px;
}

#products_module.cms_list .cms_item_divider,
#products_module.cms_entity .cms_item_divider {
	margin-bottom: 15px;
	margin-top: 20px;
}

#products_module.cms_entity .cms_content .image {
	margin-right: 15px;
	margin-left: 0px;
}

@media (max-width: 767px) {
	#products_module.cms_list td,
	#products_module.cms_list .cms_list_item.left,
	#products_module.cms_list .cms_list_item.right {
		display: inline-block;
		width: 100%;
		padding-left: 0px;
		text-align: left;
	}

	#products_module.cms_list .cms_list_item.left table tr td,
	#products_module.cms_list .cms_list_item.right table tr td {
		padding-top: 7px;
		padding-bottom: 7px;
	}

	#products_module.cms_list .tile_description > a {
		display: inline-block;
	}

	#products_module.cms_list .cms_list_item .image {
		padding-left: 0px;
		padding-bottom: 0px;
	}

	#products_module.cms_entity .cms_content .image {
		margin-right: 0px;
		margin-left: 0px;
		display: block;
	}

	#products_module.cms_entity .cms_content p {
		display: inline-block;
	}
}

/* posts */
.collection.posts-collection .node.posts-node {
	border-top: 1px solid #e1e3e0;
	padding-bottom: 20px;
	padding-top: 17px;
	margin-top: 0px;
}

.collection.posts-collection .node.posts-node:first-child {
	padding-top: 0px;
	border-top: 0px;
}

.collection.posts-collection .node.posts-node .field.posts-field h2 {
	margin-top: 0px;
}

.node.posts-node .post-comment {
	padding-top: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #e1e3e0;
}

.node.posts-node .posts-comments,
.node.posts-node #post-add-comment {
	margin-top: 20px;
}

.posts-node .post-comment-author {
	font-size: 0.8rem;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 39%;
}

.inside-page-nested-right-column .box.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	margin-bottom: 8px;
}

#posts_module.form .ui-form-panel {
	margin-top: 10px;
}

@media (max-width: 991px) {
	.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"],
	.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input textarea,
	#posts_module.form .form_item .ui-form-input {
		width: 100% !important;
	}

	#posts_module.form .form_item .ui-form-label,
	#posts_module.form .form_item .required .ui-form-label {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
	}

	.posts-node .posts-comments #post-add-comment .ui-form-fields {
		margin-bottom: 0px;
	}
}


/* resources */
#moduleDocuments_module.cms_list .cms_items h4 {
	margin-top: 0;
}

#moduleDocuments_module.cms_list .cms_title .icon {
	margin-bottom: 4px;
}

#moduleDocuments_module.cms_list .cms_description {
	background-position: 0px 4px;
}

#moduleDocuments_module.cms_list .cms_link {
	color: #d81118;
}

/* rss feeds */
#feeds_module.cms_list .cms_item_divider {
	margin-bottom: 18px;
	margin-top: 18px;
}

#feeds_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
	margin-bottom: 0px;
}

#feeds_module.cms_list .cms_list_item .cms_date h4 {
	margin-bottom: 0px;
	margin-top: 0px;
	font-size: 1rem;
}

#feeds_module.cms_list .cms_list_item .abstract {
	padding-bottom: 0px;
}

/* staff */
#staffdirectory_module.cms_list .cms_item_divider {
	margin-bottom: 19px;
	margin-top: 25px;
}

#staffdirectory_module.cms_list .cms_list_item table td.image {
	padding-right: 15px;
	padding-top: 6px;
}

#staffdirectory_module.cms_list .cms_list_item .cms_metadata1.cms_title a {
	color: #ee0008;
	font-size: 1.1875em;
	font-weight: 500;
	letter-spacing: 0.01em;
	line-height: 1.5em;
}

#staffdirectory_module.cms_list .cms_list_subheading {
	color: #ee0008;
	font-family: "Roboto Slab", sans-serif;
	font-size: 1.5em;
	font-weight: 400;
	letter-spacing: 0.01em;
	line-height: 1.375em;
}

#staffdirectory_module.cms_list .cms_list_item .cms_metadata2.cms_title {
	font-weight: bold;
}

#staffdirectory_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

#staffdirectory_module.cms_entity .footer {
	padding-top: 20px;
}

@media (max-width: 767px) {
	#staffdirectory_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#staffdirectory_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* services */
#services_module.list .list_item .footer {
	height: 21px;
	border-bottom: 1px solid #e1e3e0;
}

#services_module.entity .image {
	float: left;
	margin: 0 20px 2px 0;
}

@media (max-width: 767px) {
	#services_module.entity .image {
		float: none;
		display: block;
		padding: 0px;
		margin: 0 0px 10px 0;
	}
}

/* submission forms */
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 0px;
}

.module-forms.confirmation .step-title {
	font-size: 1.2rem;
}

#submissionforms_module.cms_form .ui-datepicker-trigger {
	top: -2px;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 6px 12px;
}

.ui-datepicker table,
.ui-datepicker .ui-datepicker-title select {
	font-size: 0.85rem;
}

@media (max-width: 991px) {
	#submissionforms_module.cms_form .cms_field input.hasDatepicker {
		width: calc(100% - 20px);
	}

	#submissionforms_module.cms_form .cms_label div {
		display: inline;
	}
}

/* succes stories */
#stories_module.cms_list .cms_item_divider,
#stories_module.cms_entity .cms_item_divider {
	margin-bottom: 19px;
	margin-top: 21px;
}

#stories_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

@media (max-width: 767px) {
	#stories_module.cms_list .cms_list_item .image {
		display: block;
		float: none;
		margin-left: 0px;
		padding-left: 0px;
		margin-top: 7px;
		margin-bottom: 7px;
	}
}

/* testimonials */
#testimonials_module.cms_list .cms_list_item {
	margin-bottom: 20px;
	margin-top: 20px;
}

#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

@media (max-width: 991px) {
	#testimonials_module.cms_list .cms_list_item .cms_bottom {
		text-align: left;
		margin-bottom: 5px;
	}

	#testimonials_module.cms_list .cms_list_item .cms_content p {
		display: inline-block;
		width: 100%;
	}

	#testimonials_module.cms_list .cms_list_item .image {
		padding: 0px 0px 10px 0px;
		float: left;
	}
}

/* videos */
#video_module.cms_list .cms_list_item .cms_video {
	padding-right: 15px;
}

#video_module.cms_list .cms_list_item table td {
	border-bottom: 1px solid #e1e3e0;
	padding-bottom: 23px;
	padding-top: 15px;
}

@media (max-width: 767px) {
	#video_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
		padding-left: 0px;
		text-align: left;
	}

	#video_module.cms_list .cms_list_item .cms_video {
		border-bottom: 0px;
		padding-bottom: 0px;
		padding-top: 18px;
	}
}

/* white papers */
#whitepapers_module.cms_list .cms_list_item {
	margin-bottom: 27px;
	margin-top: 27px;
}

@media (max-width: 767px) {
	#whitepapers_module.cms_list .cms_title,
	#whitepapers_module.cms_list .abstract {
		display: inline-block;
		width: 100%;
	}

	#whitepapers_module.cms_list .cms_list_item .image {
		padding: 0px 0px 10px 0px;
		float: left;
	}
}

/*************** Intranet Modules *****************/
/*************************************************/
/* classified*/
.collection.classifieds-collection .classifieds-field h2 {
	margin-top: 0px;
}

.collection.classifieds-collection .classifieds-node {
	border-bottom: 1px solid #e1e3e0;
	border-top: 0px;
	padding-bottom: 17px;
	margin-top: 10px;
}

.collection.classifieds-collection .classifieds-node:first-child {
	margin-top: 0px;
}

/* ideas*/
.collection.ideas-collection .ideas-node h2 {
	margin-top: 0px;
}

.collection.ideas-collection .ideas-node {
	border-bottom: 1px solid #e1e3e0;
	border-top: 0px;
	padding-bottom: 17px;
	margin-top: 10px;
}

.collection.ideas-collection .ideas-node:first-child {
	margin-top: 0px;
}

/* kudos*/
.collection.kudos-collection .kudos-node h2 {
	margin-top: 0px;
}

.collection.kudos-collection .kudos-node {
	border-bottom: 1px solid #e1e3e0;
	border-top: 0px;
	padding-bottom: 17px;
	margin-top: 10px;
}

.collection.kudos-collection .kudos-node:first-child {
	margin-top: 0px;
}

/* resources */
.resource-collection .resource-option {
	background: none;
}

.resource-collection .resource-option .select2-container--default .select2-selection--single {
	border-radius: 0px;
	border: 1px solid #cccccc;
}

.module-reservations .ui-form-container {
	border: 0px;
}

.module-reservations .reservations-module.reservations .ui-form-input input[type="text"],
.module-reservations .reservations-module.reservations .ui-form-input select,
.module-reservations .reservations-module.reservations .ui-form-input textarea {
	border: 1px solid #cccccc;
	margin-top: 0px;
}

.reservations-module.reservations .ui-form-legend-wrapper {
	width: 100%;
	margin-bottom: 15px;
}

.reservations-module.reservations .ui-form-legend {
	height: auto;
	border-bottom: none;
	text-transform: none;
	background: #ccc;
	display: inline-block;
	width: 100%;
}

.reservations-module.reservations .ui-form-legend span.icon {
	margin-left: 7px;
	margin-top: 6px;
	margin-right: 7px;
}

.reservations-module.reservations .ui-form-legend .ui-form-legend-text {
	font-weight: bold;
	margin-right: 10px;
}

.reservations-module.reservations .ui-form-fieldset {
	margin-top: 10px;
}

.reservations-module.reservations .ui-form-fields {
	border-bottom: 0px;
	margin-bottom: 10px;
}

.reservations-module.reservations .ui-form-label {
	color: inherit;
	display: inline-block;
	font-weight: normal;
}

@media (max-width: 1024px) {
	.module-reservations .reservations-module.reservations .ui-form-input input[type="text"],
	.module-reservations .reservations-module.reservations .ui-form-input select,
	.module-reservations .reservations-module.reservations .ui-form-input textarea,
	.reservations-module.reservations .ui-form-label {
		margin-left: 0px !important;
		width: 100% !important;
	}
}

/********************* Accounts *************************/
/*******************************************************/
#account_module.form .more_options ul {
	padding-left: 0px;
	width: 100%;
	text-align: center;
}

#account_module.form .form_container .login_field {
	width: 212px !important;
}

#account_module.form .form_item.required .field,
#account_module.form .form_item .field {
	background: none;
	padding-left: 0px;
}

#account_module.form .form_item .label,
#account_module.form .form_item.required .label {
	padding-right: 15px;
}

#account_module.form .form_item .label label {
	display: inline;
}

#account_module.form .form_item.required .label::after {
	content: '*';
	color: #ee0000;
	padding-left: 5px;
	font-style: italic;
	font-weight: 400;
}

#account_module.form .form_subheading {
	color: #000;
	font-weight: bold;
	border-bottom: 1px solid #e1e3e0;
}

#account_module.form .required_message span {
	background: none;
}

#account_module.form .required_message span::before {
	content: "*";
	color: #ee0000;
	font-style: italic;
	padding-right: 10px;
}

@media (max-width: 991px) {
	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: auto !important;
		padding-bottom: 4px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0px;
		background: none;
		float: none;
		position: static;
	}

	#account_module.form .ui-form-fields {
		padding-left: 0px;
	}

	#account_module.form .ui-form-field .ui-form-input {
		display: block;
	}

	#account_module.form .ui-form-field .ui-form-label {
		height: auto;
		left: 0px;
		line-height: 1.125rem;
		padding: 6px 6px 6px 0;
		position: static;
		text-align: left;
		top: 6px;
		width: 100%;
	}

	#account_module.form .form_container .login_field {
		width: 100% !important;
	}
}

/*************** ui form styles *************************/
/*******************************************************/
.ui-form-legend {
	background: none;
	padding-left: 0px;
	padding-right: 0px;
}

.ui-form-container .ui-form-panel {
	border: 0px;
	padding: 0px !important;
}

.ui-form-container .ui-form-input.ui-form-file {
	font-size: 0.8125rem;
}

.ui-form-container .ui-form-field {
	padding-bottom: 0px;
	padding-top: 0px;
}

.ui-form-container .ui-form-field.required .ui-form-label em {
	color: #ee0000;
}

.ui-form-container .ui-form-input.ui-form-file input[type="file"] {
	padding-left: 0px;
	padding-top: 0px;
}

.ui-form-container .ui-form-input.ui-form-textarea textarea {
	border: 1px solid #cccccc;
	color: #333;
}

.ui-form-container .ui-form-fields .select2-choices {
	box-shadow: none;
	border-radius: 0px;
	border: 1px solid #cccccc;
	padding-left: 8px;
}

.ui-form-container .ui-form-fields .select2-choices .select2-input {
	font-size: 0.9375rem;
}

.ui-form-container .ui-form-note {
	line-height: 1.25rem;
	margin-bottom: 10px;
}

.ui-form-buttons {
	display: block;
	padding-left: 0px;
	position: static;
	text-align: center;
}

.ui-form-buttons .ui-form-field {
	display: inline-block;
	float: none;
}

.ui-form-buttons .ui-form-field .ui-form-button button {
	margin: 0px;
}

.ui-form-buttons .ui-form-field .ui-form-button a {
	display: inline-block;
	padding: 8px 14px;
}

.ui-form-field .select2-container-multi .select2-choices {
	padding-bottom: 0px;
	padding-top: 0px;
}

.ui-form-buttons.ui-form-field-shipping.s-wide-button {
	padding-left: 0px !important;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding-left: 0px;
	}

	.ui-form-container .ui-form-label,
	.ui-form-container .ui-form-fields,
	.ui-form-container .ui-form-fields .ui-form-field {
		display: block;
		position: static;
		left: auto;
		float: none;
		height: auto;
		width: 100%;
	}

	.ui-form-container .ui-form-input.ui-form-textarea textarea,
	.ui-form-container .ui-form-input.ui-form-text input[type="text"] {
		width: 100% !important;
	}

	.ui-form-container .ui-form-fields .select2-container {
		width: 100% !important;
	}

	.ui-form-container .ui-form-fields .select2-choices {
		width: 100% !important;
	}

	.ui-form-label {
		text-align: left;
	}

	.ui-form-text-phone-extension {
		margin-left: 0;
		width: 100%;
	}

	.ui-form-select > select,
	.ui-form-textarea > textarea,
	.ui-form-text > input[type="password"],
	.ui-form-text > input[type="text"] {
		max-width: 100%;
	}
}

/*************** Law firm modules ***********************/
/*******************************************************/
/*industry groups*/
#module-industrygroups .industry-group {
	border-bottom: 1px solid #e1e3e0;
	display: inline-block;
	width: 100%;
	padding-bottom: 14px;
	margin-bottom: 9px;
}

#module-industrygroups .industry-group:last-child {
	border: none;
}

#module-industrygroups #group-sidebar {
	display: inline-block;
	width: 100%;
}

#module-industrygroups #group-sidebar #group-attorneys {
	padding-left: 0px;
	padding-top: 0px;
	border-bottom: 1px solid #e1e3e0;
	margin-bottom: 20px;
	padding-bottom: 20px;
}

#module-industrygroups #group-sidebar #group-attorneys h2 {
	margin-top: 0px;
	color: #000;
}

#module-industrygroups #group-main {
	width: 100% !important;
}

#module-industrygroups #group-sidebar #group-attorneys ul li a {
	color: #ee0008;
}

#module-industrygroups #group-sidebar #group-attorneys ul li a:hover {
	color: #ee0008;
	text-decoration: underline;
}

/*practise areas*/
#module-practiceareas {
	margin-top: 0px;
}

#module-practiceareas .area-abstract p {
	font-size: inherit;
}

#module-practiceareas .practice-area,
#module-practiceareas.thumbnail-view .main-list-item-wrap,
#module-practiceareas #area-sidebar #area-attorneys,
#module-practiceareas #area-sidebar #area-industrygroups {
	border-bottom: 1px solid #e1e3e0;
	margin-bottom: 14px;
	padding: 0 0 20px;
	padding-top: 0px;
	padding-left: 0px;
	padding-right: 0px;
	display: inline-block;
	width: 100%;
}

#module-practiceareas .area-title,
#module-practiceareas .area-children .column {
	margin: 0px;
	padding: 0px;
}

#module-practiceareas img,
#module-practiceareas.thumbnail-view .main-list-item-image {
	max-width: 100%;
	height: auto;
}

#module-practiceareas .area-image {
	width: auto;
}

#module-practiceareas .area-children a {
	padding-left: 0px;
}

#module-practiceareas .area-title,
#module-practiceareas .area-children .column {
	width: 100%;
}

#module-practiceareas #area-sidebar,
#module-practiceareas.thumbnail-view .main-list-summary-column.no-thumbnail,
#module-practiceareas.thumbnail-view .main-list-related-areas-column {
	display: inline-block;
	width: 100%;
}

#module-practiceareas.thumbnail-view .main-list-related-areas-column {
	margin-top: 5px;
}

#module-practiceareas #area-sidebar #area-attorneys,
#module-practiceareas #area-sidebar #area-industrygroups {
	padding-left: 0px;
	padding-right: 0px;
	padding-bottom: 20px;
	padding-top: 0px;
}

#module-practiceareas #area-sidebar #area-attorneys h2,
#module-practiceareas #area-sidebar #area-industrygroups h2 {
	margin-top: 0px;
}

#module-practiceareas.thumbnail-view .main-list-item-related-areas-title {
	border-top: 0px;
	background-image: none;
	font-size: 1.1rem;
	margin-bottom: 0px;
}

#module-practiceareas.thumbnail-view .main-list-item-related-areas-list {
	margin-bottom: 0px;
	list-style-type: none;
	padding-left: 0px;
}

#module-practiceareas.thumbnail-view .main-list-image-column {
	text-align: left;
}

#module-practiceareas #area-main {
	width: 100% !important;
}

#module-practiceareas #area-sidebar img {
	margin-bottom: 20px;
}

@media (max-width: 991px) {
	#module-practiceareas .area-title,
	#module-practiceareas .area-children .column {
		border-bottom: 0px;
	}

	#module-practiceareas .area-children a {
		padding-right: 0px;
	}

	#module-practiceareas.thumbnail-view .main-list-item-summary-wrap {
		margin-bottom: 0px;
		padding-bottom: 0px;
	}
}

@media (max-width: 767px) {
	#module-practiceareas .area-image {
		float: none;
		margin: 10px 0px 10px 0px;
	}

	#module-practiceareas.thumbnail-view .main-list-image-column,
	#module-practiceareas.thumbnail-view .main-list-summary-column,
	#module-practiceareas.thumbnail-view .main-list-related-areas-column {
		text-align: left;
		padding-left: 0px;
	}
}

/*attorneys*/
#module-attorneys {
	margin-top: 0px;
}

#module-attorneys table tbody tr:hover td {
	background-color: #e1e3e0;
}

#module-attorneys table tbody tr td a:hover {
	text-decoration: none;
}

#module-attorneys.profile img {
	margin-bottom: 20px;
	height: auto;
	margin-top: 14px;
}

#module-attorneys.profile .content-box h2 {
	color: #ee0008;
	font-family: "Open Sans", sans-serif;
	text-transform: none;
	margin-top: 0px;
}

#module-attorneys.profile #info-left,
#module-attorneys.profile #info-right {
	width: 100%;
}

#module-attorneys.profile #info-left .email a,
#module-attorneys.profile #info-left .location {
	text-transform: none;
}

#module-attorneys.profile #info-left .email a,
#module-attorneys.profile #info-right .content-box.practice-areas a,
#module-attorneys.profile #info-right .content-box.industry-groups a,
#module-attorneys.profile #info-download .vcard {
	color: #ee0008;
}

#module-attorneys.profile #info-right .content-box.practice-areas,
#module-attorneys.profile #info-right .content-box,
#module-attorneys.profile #info-right .content-box.industry-groups,
#module-attorneys.profile .biography {
	margin-bottom: 17px;
	padding-bottom: 23px;
	padding-top: 0px;
	padding-left: 0px;
	padding-right: 0px;
	display: inline-block;
	width: 100%;
}

#module-attorneys.profile .biography {
	padding-bottom: 14px;
}

#module-attorneys.profile #info-download .vcard {
	background: none;
	padding-left: 0px;
}

#module-attorneys.profile #info-right .content-box,
#module-attorneys.profile .biography {
	border-bottom: 1px solid #e1e3e0;
}

#module-attorneys table a,
#module-attorneys.alpha table thead tr td,
#module-attorneys.practice table tr.thead td,
#module-attorneys.alpha #letter-navigation a,
#module-attorneys.alpha .alpha-indicator,
#module-attorneys .column-headers td {
	color: #000;
}

#module-attorneys.photo .attorney a {
	color: #ee0008;
}

#module-attorneys .section-header {
	text-transform: none;
	padding-bottom: 7px;
	border-bottom: 1px solid #ee0008;
	color: #ee0008;
}

#module-attorneys.location .column-headers td {
	padding-top: 10px;
	padding-bottom: 10px;
}

#module-attorneys.photo .attorney {
	width: 20%;
	margin-right: 5%;
	height: 200px;
}

#module-attorneys.photo .attorney .image {
	width: 100%;
}

#module-attorneys.photo .attorney .image {
	height: auto;
}

#module-attorneys.photo .attorney .image img {
	height: auto !important;
	max-width: 130px;
}

@media (max-width: 991px) {
	#module-attorneys.alpha table tr td,
	#module-attorneys.practice table tr td,
	#module-attorneys.location table tr td {
		display: block;
		height: auto;
	}

	#module-attorneys.alpha table tr:hover td,
	#module-attorneys.practice table tr:hover td,
	#module-attorneys.location table tr:hover td {
		background: none;
	}

	#module-attorneys.alpha .column-headers,
	#module-attorneys.alpha .alpha-indicator,
	#module-attorneys.practice .column-headers,
	#module-attorneys.practice table tr th,
	#module-attorneys.location .column-headers,
	#module-attorneys.location table tr th {
		display: none;
	}

	#module-attorneys.alpha tr[class^="letter"] th {
		border-bottom: 1px solid #e1e3e0;
		display: block;
		height: 1px !important;
		padding-top: 6px;
		width: 100%;
	}

	#module-attorneys.alpha table tr td:last-child,
	#module-attorneys.practice table tr td:last-child,
	#module-attorneys.location table tr td:last-child {
		padding-bottom: 12px;
	}

	#module-attorneys.practice table tr td .section-header {
		padding-top: 7px;
	}

	#module-attorneys.practice .practice-area-sub-header {
		margin: 0px;
		padding: 0px;
		border-bottom: 1px solid #000;
	}

	#module-attorneys.photo .attorney {
		width: 28%;
	}
}

@media (max-width: 767px) {
	#module-attorneys.photo .attorney {
		width: 45%;
	}
}

/*************** Pagination ****************************/
/*******************************************************/
.pagination-wrapper {
	font-size: 0.8125rem;
}

.pagination-wrapper .pagination-sort-items {
	padding: 0px;
}

.pagination-wrapper .pagination-page-selection-top {
	text-align: left;
}

.pagination-wrapper .pagination-current-items-top {
	float: right;
}

.pagination-wrapper .pagination-current-page select,
.pagination-wrapper .pagination-items-per-page select,
.pagination-wrapper .pagination-filter select {
	width: auto !important;
	max-width: 100%;
	height: auto;
	margin-bottom: 0px;
	padding: 4px 2px;
	display: inline;
}

@media ( max-width: 767px ) and ( min-width: 479px ) {
	.pagination-wrapper .pagination-filter.pagination-sort-items {
		width: 60%;
	}

	.pagination-wrapper .pagination-filter.pagination-current-items-top {
		width: 40%;
	}
}


/*************** eCommerce modules ***********************/
/*******************************************************/
#products_module.responsive .items.items-categories.grid-view {
	margin-bottom: 35px;
}

#products_module.responsive .items.grid-view .item-wrapper .item {
	margin-top: 0px;
	margin-bottom: 20px;
}

#products_module.responsive.cms_list .items-categories.grid-view .item-wrapper .item .cms_title h3 {
	line-height: 1.2rem;
}

#products_module.responsive .item-sale-price {
	font-weight: bold;
}

#products_module.responsive .items .item-wrapper .item .item-description p {
	font-size: 0.9rem;
	line-height: 1.2rem;
}

#products_module.responsive .items.list-view .item-wrapper .item {
	border-bottom: 1px solid #e1e3e0;
	margin-bottom: 10px;
	padding-bottom: 20px;
	margin-top: 10px;
}

#products_module.responsive.cms_entity .related-items {
	display: inline-block;
	width: 100%;
}

#products_module.responsive.cms_entity .add-to-cart-region input.hasDatepicker[type="text"] {
	margin-bottom: 0px;
	width: 100% !important;
	max-width: 100% !important;
}

#products_module.responsive.cms_entity .related-items .related-items-title {
	font-size: 1.2rem;
}

#products_module.responsive.cms_entity .item-price {
	font-size: 1.1rem;
	font-weight: bold;
}

#products_module.responsive.cms_entity .product-sku {
	font-size: 1.1rem;
}

#products_module.responsive .availability_box .available {
	color: #000;
}

#products_module.responsive .add_to_cart_form .quantity_row .product-quantity-label {
	width: 30%;
}

.product-attribute-file-upload label,
#products_module.responsive .add_to_cart_form .attributeSelectionLabel {
	font-weight: bold;
}

#products_module.responsive .add_to_cart_form .submit_row_center {
	margin-top: 15px;
}

#products_module.responsive .tab-item-content .product-review .product-review-author {
	font-size: 0.8125rem;
}

#products_module.responsive.cms_form .ui-form-label {
	text-align: left;
}

#products_module.responsive.cms_form #formAskQuestion .ui-form-radio label,
#products_module.responsive.cms_form .preferred-contact-method-label.ui-form-label {
	font-weight: normal;
}

#products_module.responsive.cms_form .required-fields-information em {
	color: #ee0000;
}

#products_module.responsive.cms_form .ui-form-radio {
	margin-right: 10px;
}


#products_module.responsive .tab-item-content .product-review {
	background: #e1e3e0;
}

#s-cart #s-estimate-shipping #postalcode-button {
	margin: 0px;
}

#s-cart #s-estimate-shipping #PostalCode {
	margin: 0px;
	padding: 7px 12px 8px 12px;
}

#s-customer #s-sign-in .s-signin-options label {
	font-weight: normal;
}

#s-customer #s-sign-in .s-signin-options label em,
#s-customer #s-register label em,
#s-customer #s-sign-in-form label em {
	color: #ee0000;
}

#s-checkout .ui-form-buttons.ui-form-field-place-order {
	padding-left: 15px;
}

#ffShippingPhone .ui-form-input.ui-form-text {
	overflow: hidden;
}

.ui-form-buttons.ui-form-field-payment.balance-and-points-zero-hide {
	padding-left: 0px !important;
}

#s-customer #s-sign-in .ui-form-input {
	max-width: 100%;
}

#s-checkout button.s-wide-button {
	margin-top: 7px;
}

@media ( max-width: 1024px ) {
	#s-cart .w-col.s-summary #s-buttons .s-button-left,
	#s-cart .w-col.s-summary #s-buttons .s-button-right,
	#s-cart .w-col.s-summary #s-estimate-shipping .s-fields.s-button,
	#s-cart #s-estimate-shipping #PostalCode,
	#s-cart .w-col.s-summary #s-estimate-shipping .s-fields.s-form,
	#s-cart #s-promotions .s-form-container input,
	#s-cart #s-promotions.s-expanded .s-form-container .s-fields.s-form {
		width: 100%;
	}
}

@media ( max-width: 767px ) {
	#products_module.responsive .items.list-view .item-wrapper .item .item-description {
		margin-left: 0px;
	}

	#products_module.responsive .items.list-view .item-wrapper .item .item-image {
		float: none;
		margin-bottom: 3px;
	}

	#products_module.responsive .add_to_cart_form .quantity_row .product-quantity-label,
	#products_module.responsive .add_to_cart_form .quantity_row input {
		width: 100%;
		margin-left: 0px;
	}

	#s-cart .s-row input.s-quantity-field,
	#s-wishlist .s-row input.s-quantity-field,
	#s-favorites .s-row input.s-quantity-field {
		height: auto;
	}

	#s-cart #s-promotions .s-form-container button {
		margin-top: 5px;
		margin-left: 0px;
	}
}


/**************** Medical Modules **********************/
/*****************************************************/
.physician-search-accordion.in-main-content-area.open .form-reveal-wrap {
	overflow: visible;
}

.physician-search-accordion .form-reveal-wrap .search-form-section-heading .title-span {
	background-color: #ee0008;
	color: white;
	border: 0 none;
}

.physician-search-accordion.in-main-content-area .search-reveal-trigger {
	margin-right: 2px;
}

.physician-search-accordion .form-reveal-wrap .physician-search-field .top-search-submit {
	margin-top: 1.9rem;
	min-width: 70%;
}

.physician-search-accordion .form-reveal-wrap .physician-search-field .form-reset {
	margin-top: 2.3rem;
}

.physician-search-accordion .form-reveal-wrap .physician-search-field .bottom-search-submit {
	background-color: #000;
}

.physician-search-accordion .form-reveal-wrap .physician-search-field .bottom-search-submit:hover {
	background-color: #d81118;
}


.physician-search-accordion .form-reveal-wrap .physician-search-field.by-name .cms_form_button.bottom-search-submit.primary {
	margin-right: 0px;
}

#physicians-module.node .md-row.physician-profile-section,
#physicians-module .md-row.doctor-profile-section {
	margin-top: 1.1rem !important;
}

#physicians-module.node .md-row.md-patient-comment-block,
#physicians-module.node .md-patient-rating-card {
	background-color: #e1e3e0;
}

#specialties-module.collection.specialties-collection.resources-collection .divider {
	height: 1px;
}

@media ( max-width: 991px ) {
	#physicians-module.node .md-row.physician-profile-section,
	#physicians-module .md-row.doctor-profile-section {
		padding-top: 0.75rem !important;
	}
}

@media ( max-width: 767px ) {
	.physician-search-accordion .form-reveal-wrap .physician-search-field .form-reset {
		margin-top: 0.7rem;
	}

	#specialties-module.node.specialty-node .floated-image {
		float: none;
		margin-left: 0px;
		padding-left: 0px;
	}
}

@media ( max-width: 479px ) {
	.physician-search-accordion.in-main-content-area .search-title {
		font-size: 1.1em;
		margin-bottom: 0px;
		margin-top: 12px;
	}

	.physician-search-accordion.in-main-content-area .search-reveal-trigger {
		margin-bottom: 6px;
	}
}

/**************** Content Boxes **********************/
/*****************************************************/
/* alerts */
.cb1 #alerts_module.homepage_contentbox.list_alerts {
	padding: 20px 20px 10px 20px;
}

#alerts_module.homepage_contentbox.list_alerts .alerts a:link,
#alerts_module.homepage_contentbox.list_alerts .alerts a:active,
#alerts_module.homepage_contentbox.list_alerts .alerts a:visited {
	color: #ee0008;
	font-size: 0.9375rem;
}

#alerts_module.homepage_contentbox.list_alerts ul {
	list-style-type: none;
}

#alerts_module.homepage_contentbox.list_alerts ul li,
#alerts_module.homepage_contentbox.list_alerts ul li:last-of-type {
	font-size: 0.9rem;
	border-top: 0px;
	border-bottom: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
}

/* posts */
.cb1 .collection.posts-collection {
	padding: 20px 20px 10px 20px;
}

.cb1 #posts-module.collection.posts-collection.homepage-contentbox.article-items {
	padding: 20px 20px 10px 20px;
}

.cb1 .posts-homepage-block.primary {
	margin-left: 20px;
	margin-bottom: 20px;
}

#posts-module.collection.posts-collection.homepage-contentbox.article-items {
	margin-bottom: 0px;
	padding: 0px;
}

#posts-module.collection.posts-collection.homepage-contentbox.article-items .article {
	width: 100% !important;
	max-width: 100%;
	padding-left: 0px;
	padding-right: 0px;
}

#posts-module.posts-collection.homepage-contentbox.article-items .article-content,
#posts-module.posts-collection.homepage-contentbox.article-items .article-image-link {
	float: none;
	width: 100%;
	padding-left: 0px;
}

.posts-collection.homepage-contentbox.article-items .article-image {
	max-width: 300px;
	height: auto;
}

/* classified */
.cb1 .collection.classifieds-collection {
	padding: 20px 20px 10px 20px;
}

/* events */
#events_module.homepage_contentbox {
	margin-top: -27px;
}

.cb1 #events_module.homepage_contentbox {
	margin-top: 0px;
}

.cb1 #events_module.homepage_contentbox {
	padding: 10px 20px 30px 20px;
}

.cb1 #events_module.homepage_contentbox table {
	width: 100%;
}

#events_module.homepage_contentbox div {
	margin-bottom: 7px;
}

#events_module.homepage_contentbox .registration_open {
	background-position: 0 5px;
}

/* forum */
#forums_module.homepage_contentbox {
	margin-bottom: 20px;
}

#forums_module.homepage_contentbox .cms_list_item .cms_title h3 {
	font-size: 1rem;
	margin-bottom: 0px;
}

.cb1 #forums_module.homepage_contentbox {
	padding: 20px 20px 10px 20px;
}

/* ideas */
.homepage-section .collection.ideas-collection .ideas-node,
.cb1 .collection.ideas-collection .ideas-node {
	padding-bottom: 0px;
}

.homepage-section .collection.ideas-collection {
	margin-bottom: 20px;
}

.cb1 .collection.ideas-collection {
	padding: 20px 20px 10px 20px;
}

/* kudos */
.homepage-section .collection.kudos-collection .kudos-node,
.cb1 .collection.kudos-collection .kudos-node {
	padding-bottom: 0px;
}

.homepage-section .collection.kudos-collection {
	margin-bottom: 20px;
}

.cb1 .collection.kudos-collection {
	padding: 20px 20px 10px 20px;
}

/* news */
.cb1 #news_module.homepage_contentbox {
	padding: 10px 20px 30px 20px;
}

#news_module.homepage_contentbox .cms_list_item .cms_title h3 {
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5rem;
	margin-bottom: 0.25rem;
	margin-top: 0rem;
}

#news_module.homepage_contentbox .cms_list_item .cms_date h3 {
	color: #474848;
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 1.1rem;
	margin-bottom: 0.25rem;
	margin-top: 0.25rem;
}

#news_module.homepage_contentbox.displaymode_list > a {
	display: none;
}

/* files */
.cb1 .collection.files-collection {
	padding: 10px 20px 30px 20px;
}

/* feeds */
.cb1 #feeds_module.homepage_contentbox {
	padding: 10px 20px 30px 20px;
}

#feeds_module.homepage_contentbox .item {
	background-position: 0px 5px;
}

/* partners */
.cb1 #partners_module.homepage_contentbox {
	padding: 10px 20px 30px 20px;
}

.cb1 #polls {
	padding: 10px 20px 30px 20px;
}

/* practise areas */
#module-practiceareas.homepage_contentbox.slideshow .practice-areas-slider-indicators {
	display: none;
}

#module-practiceareas.homepage_contentbox.slideshow .content-box-image-link {
	border-bottom: 0px;
}

/* products ecommerce */
#products_module.responsive .items .item-wrapper .item .item-description .metadata1.cms_title h3 {
	line-height: 1.4rem;
	margin-bottom: 7px;
	margin-top: 7px;
}

#products_module.responsive .items .item-wrapper .item .item-image {
	border: 0px;
}

#products_module.homepage_contentbox .cms_list_item_grid_container {
	padding-bottom: 14px;
}

#products_module.homepage_contentbox .cms_list_item_grid_container .cms_content p {
	margin-bottom: 5px;
	line-height: 1.2rem;
}

.cb1 #products_module.homepage_contentbox {
	padding: 10px 20px 30px 20px;
}

#products_module.homepage_contentbox.displaymode_category_list td {
	width: 50%;
}

#products_module.homepage_contentbox.displaymode_category_list td a {
	line-height: 1.2rem;
}

#products_module.homepage_contentbox.displaymode_category_list td .links {
	padding-bottom: 12px;
	line-height: 1.2rem;
}

.homepage-section #products_module.featured_list .cms-slideshow-items,
.cb1 #products_module.featured_list .cms-slideshow-items {
	height: 200px;
	margin: 0 auto;
	text-align: center;
	width: 200px;
}

.homepage-section #products_module.featured_list .cms-slideshow-items .cms-slideshow-item,
.cb1 #products_module.featured_list .cms-slideshow-items .cms-slideshow-item {
	width: 100%;
}

.homepage-section #products_module.featured_list .cms-slideshow-items .cms-slideshow-item div,
.cb1 #products_module.featured_list .cms-slideshow-items .cms-slideshow-item div {
	margin: 0 auto;
}

.homepage-section #products_module.featured_list .cms-slideshow-items .cms-slideshow-item div img,
.cb1 #products_module.featured_list .cms-slideshow-items .cms-slideshow-item div img {
	height: auto;
	max-width: 100%;
}

/* testimonials */
.cb1 #testimonials_module_contentbox {
	padding: 10px 20px 30px 20px;
}

#testimonials_module_contentbox.contentbox_item {
	margin-bottom: 10px;
	margin-top: 0;
	color: #000;
	font-size: 0.95rem;
	font-weight: 400;
	line-height: 1.5rem;
}

#testimonials_module_contentbox.contentbox_item .company {
	font-style: italic;
	display: inline-block;
	width: 100%;
}

#testimonials_module_contentbox.contentbox_item .author-name {
	font-style: italic;
	display: inline-block;
	width: 100%;
}

/* blog */
.cb1 #blogs_module.homepage_contentbox {
	padding: 10px 20px 30px 20px;
}

/* video */
.homepage-featured-video iframe {
	max-width: 100%;
}

/* forms */
.cb1 #submissionforms_module.homepage_contentbox {
	padding: 10px 20px 10px 20px;
	text-align: left;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	max-width: 100% !important;
	width: 100%;
	background-color: #ffffff;
	border: 1px solid #d6d6d6;
	border-radius: 0;
	color: #333333;
	font-size: 0.75rem;
	padding-left: 0px;
	padding-right: 0px;
}

#submissionforms_module.homepage_contentbox label {
	display: inline;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .ui-datepicker-trigger {
	margin-left: 3px;
	position: relative;
	top: -5px;
	left: 0px;
}

#submissionforms_module.homepage_contentbox input.hasDatepicker[type="text"] {
	width: 90%;
	float: left;
}

#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button {
	border: none;
	padding: 0.375rem 1rem;
}

#submissionforms_module.homepage_contentbox .radio_wrapper .field {
	padding-right: 5px;
}

@media (max-width: 991px) {
	#submissionforms_module.homepage_contentbox input.hasDatepicker[type="text"] {
		width: 80%;
	}

	#submissionforms_module.homepage_contentbox input[type="text"],
	#submissionforms_module.homepage_contentbox input[type="tel"],
	#submissionforms_module.homepage_contentbox input[type="email"],
	#submissionforms_module.homepage_contentbox select,
	#submissionforms_module.homepage_contentbox textarea,
	#submissionforms_module.homepage_contentbox input[type="file"] {
		width: 75% !important;
	}
}

@media (max-width: 767px) {
	#submissionforms_module.homepage_contentbox input.hasDatepicker[type="text"] {
		width: 90%;
	}

	#submissionforms_module.homepage_contentbox input[type="text"],
	#submissionforms_module.homepage_contentbox input[type="tel"],
	#submissionforms_module.homepage_contentbox input[type="email"],
	#submissionforms_module.homepage_contentbox select,
	#submissionforms_module.homepage_contentbox textarea,
	#submissionforms_module.homepage_contentbox input[type="file"] {
		width: 100% !important;
	}
}
